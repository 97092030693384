import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Input,
    List,
    Modal,
    notification,
    Progress,
    Row,
    Space,
    Steps,
    Table,
    Tabs,
    Tag
} from 'antd';
import {
    BarcodeOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    ScanOutlined,
    SearchOutlined
} from "@ant-design/icons";
import packedOrderService from '../../services/packed-order.service';
import './style.css'
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import {useParams} from "react-router-dom";
import _ from "underscore";

const soundWarning = require('../../assets/beep-warning.mp3');
const soundSuccess = require('../../assets/success.mp3');

const {Step} = Steps;

const columns = [
    {
        title: 'MÃ ĐƠN', dataIndex: 'order_id', key: 'order_id', render: (text) => <a>{text}</a>,
    },
    {
        title: 'NGUỒN', dataIndex: 'order_sources_name', key: 'order_sources_name', render: (text) => <a>{text}</a>,
    },
    {
        title: 'DVVC', dataIndex: 'short_name_partner', key: 'short_name_partner', render: (text) => <a>{text}</a>,
    },
    {
        title: 'TRẠNG THÁI',
        dataIndex: 'status_readable',
        key: 'status_readable',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'GỬI HÀNG',
        dataIndex: 'shipped',
        key: 'shipped',
        render: (shipped) => <a>
            {
                shipped ? <Tag color={'green'}>Đã gửi</Tag> : <Tag color={'red'}>Chờ lấy</Tag>
            }
        </a>,
    }
];

const colors_status = {
    'pending': 'red', 'returned': 'green'
}

const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'cyan',
    'exists': 'gray',
    'exists-packed': 'red',
    'failed': 'red',
}

const name_status_order_scanned = {
    'none': 'chờ xử lý',
    'new': 'thành công',
    'exists': 'Đã tồn tại',
    'failed': 'thất bại',
    'exists-packed': 'Đơn đã đóng gói trước đó',
}


const pageSize = 10;

function SolutionOutlined() {
    return null;
}

const PackedOrderPageDetail = () => {
    let {session_id} = useParams();
    console.log('session_id', session_id)
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [isModalSearchVisible, setIsModalSearchVisible] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const inputSearchRef = React.createRef();
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'ĐÓNG ĐƠN'});
    }, [])
    const audioWarning = new Audio(soundWarning);
    audioWarning.loop = false;
    const audioSuccess = new Audio(soundSuccess);
    audioSuccess.loop = false;

    const [data_shipped, setDataShipped] = useState([]);
    const [data_not_shipped, setDataNotShipped] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isModalScanCodeVisible, setIsModalScanCodeVisible] = useState(false);
    const [listOrderScanned, setListOrderScanned] = useState([]);
    const [currentIdOrder, setCurrentIdOrder] = useState('');
    const [isStartingUpdateBulk, setIsStartingUpdateBulk] = useState(false);
    const [currentStatusScan, setCurrentStatusScan] = useState('none'); // ['none', 'new', 'exists', 'updated'
    const inputScanOrderRef = React.createRef();
    const showModalScanCode = (status) => {
        setCurrentStatusScan(status)
        setIsModalScanCodeVisible(true,);
        setIsStartingUpdateBulk(false);
        setListOrderScanned([]);
    };
    const handleOkOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const handleCancelOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const [statusProgressUpdateBulk, setStatusProgressUpdateBulk] = useState('normal');
    const [percentProgressUpdateBulk, setPercentProgressUpdateBulk] = useState(0);
    const onScanOrder = () => {
        let value = inputScanOrderRef.current.input.value;
        if (value.trim().length === 0) {
            return;
        }
        //check exists

        addOrder(value)
        inputScanOrderRef.current.input.focus();
        setCurrentIdOrder('')
    };

    const addOrder = (value) => {
        console.log('addOrder', value)
        value = value.trim();
        let order = _.find(listOrderScanned, (item) => {
            return item.order_id === value;
        })
        if (order) {
            notification['error']({
                message: 'Thông báo',
                description: `Đơn hàng: ${value} đã tồn tại`
            });
            audioWarning.play();
            return;
        }
        let status = '';
        let message = '';
        packedOrderService.addOrderToSession(session_id, value)
            .then(res => res.data)
            .then((res) => {
                onReloadData();
                notification['success']({
                    message: 'Thông báo',
                    description: `Đã thêm đơn hàng: ${value} vào danh sách`
                })
                audioSuccess.play();
                status = 'new';
                message = 'Đã thêm';
            })
            .catch((err) => {
                status = 'failed';
                message = err.response.data.message;
                console.log('err', err)
                notification['error']({
                    message: 'Thông báo',
                    description: message
                });
                audioWarning.play();

            })
            .finally(() => {
                let data = [
                    ...listOrderScanned,
                    {
                        order_id: value,
                        status: status,
                        message: message
                    }
                ];
                data = _.uniq(data, order => order.order_id);
                setListOrderScanned(data);
            })
    }

    const onReloadData = () => {
        querySessions()
    }


    const onSearchOrder = (order_id) => {

        if (order_id.trim().length >= 1) {
            packedOrderService.searchOrder(order_id)
                .then(res => res.data)
                .then((res) => {
                    console.log('res', res)
                    setDataOrder(res);
                })
        } else {
        }
    };

    const querySessions = () => {
        setLoading(true);
        packedOrderService.getDetailPackedOrder(session_id)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                let data_shipped = res.filter(item => item.shipped === true);
                let data_not_shipped = res.filter(item => item.shipped === false);
                setDataShipped(data_shipped);
                setDataNotShipped(data_not_shipped);
            })
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        querySessions()
    };

    useEffect(() => {
        querySessions()
    }, []);

    function handleOkOnModalSearch() {
        onSearchOrder(inputSearchRef.current.input.value);
    }

    function handleCancelOnModalSearch() {
        setIsModalSearchVisible(false);
    }

    function showModalSearch() {
        setIsModalSearchVisible(true);
    }

    function getListOrderByStatus(status) {
        return status.includes('all') ? listOrderScanned : listOrderScanned.filter(item => status.includes(item.status));
    }

    function copyListOrder(data) {
        let list_order_id = _.map(data, (item) => {
            return item.order_id;
        })
        let text = list_order_id.join('\n');
        navigator.clipboard.writeText(text).then(function () {
            notification['success']({
                message: 'Thông báo',
                description: 'Đã copy danh sách đơn hàng'
            });
        }, function (err) {
            notification['error']({
                message: 'Thông báo',
                description: 'Đã xảy ra lỗi'
            });
        });
    }

    function renderListOrderScanned(status) {
        let data = getListOrderByStatus(status);
        return <List
            style={{
                maxHeight: 400,
                overflowY: 'scroll',
                marginTop: 10
            }}
            header={<span>DANH SÁCH ĐƠN ({data.length}) <a
                onClick={() => copyListOrder(data)}>COPY DANH SÁCH ĐƠN</a></span>}
            bordered={true}
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item key={item + index}>
                    <CheckCircleOutlined style={{color: '#52c41a'}}/> {item.order_id} <Tag
                    color={colors_status_order_scanned[item.status]}
                    style={{float: 'right'}}>{item.message}</Tag>
                </List.Item>
            )}
        />
    }

    return <div>
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Đóng hàng</Breadcrumb.Item>
        </Breadcrumb>

        <Modal title="Tìm kiếm đơn hàng" visible={isModalSearchVisible} onOk={handleOkOnModalSearch}
               onCancel={handleCancelOnModalSearch} okText={'Tìm kiếm'} cancelText={'Hủy'}
        >
            <Input
                size="large"
                placeholder="Nhập mã vận đơn"
                prefix={<SearchOutlined/>}
                ref={inputSearchRef}
                onPressEnter={() => onSearchOrder(inputSearchRef.current.input.value)}
            />
            <Divider/>
            <Steps direction={'vertical'} size={'small'} current={dataOrder.length}>
                {
                    dataOrder.map((item, index) => {
                        let icon = item.status === 'packed' ? <CheckCircleOutlined/> : <QuestionCircleOutlined/>;
                        let title = item.status === 'packed' ? 'Đã đóng gói' : 'Chưa đóng gói';
                        title = item.created_at_format + ': ' + title;
                        return <Step key={item._id} title={title} icon={<QuestionCircleOutlined/>}/>
                    })

                }
            </Steps>;

        </Modal>

        <Modal title={currentStatusScan === 'packed' ? 'Đã đóng gói' : 'Chưa gói'}
               width={800}
               visible={isModalScanCodeVisible}
               onOk={handleOkOnModalScanCode}
               onCancel={handleCancelOnModalScanCode}
               okText={'XỬ LÝ ĐƠN'}
               footer={[
                   <Button onClick={handleCancelOnModalScanCode}>Đóng</Button>,
               ]}
        >
            <Space direction={'vertical'} style={{width: '100%'}}>
                <Row align={'center'}>
                    {
                        isStartingUpdateBulk ? <Space wrap>
                            <Progress type="circle" percent={percentProgressUpdateBulk}
                                      status={statusProgressUpdateBulk}/>
                        </Space> : <Input
                            size="large"
                            placeholder="quét mã vận đơn"
                            prefix={<BarcodeOutlined/>}
                            ref={inputScanOrderRef}
                            value={currentIdOrder}
                            onPressEnter={onScanOrder}
                            onChange={(e) => setCurrentIdOrder(e.target.value)}
                        />
                    }
                </Row>

                <Tabs>
                    <Tabs.TabPane tab={<>TẤT CẢ <Tag>{getListOrderByStatus(['all']).length}</Tag></>}
                                  key="item-1">
                        {renderListOrderScanned(['all'])}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={<>THÀNH CÔNG <Tag
                            color="#87d068">{getListOrderByStatus(['success', 'exists']).length}</Tag></>}
                        key="item-2">
                        {renderListOrderScanned(['new', 'exists'])}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<>THẤT BẠI <Tag color="#f50">{getListOrderByStatus(['failed']).length}</Tag></>}
                                  key="item-3">
                        {renderListOrderScanned(['failed'])}
                    </Tabs.TabPane>
                </Tabs>

            </Space>
        </Modal>

        <div
            className="site-layout-background"
            style={{
                padding: 24, minHeight: 360,
            }}
        >
            <Row style={{}}>
                <Col>
                    <Space>
                        <Button type={"primary"} icon={<ScanOutlined/>} onClick={() => showModalScanCode('packed')}
                                style={{background: 'rgb(82, 196, 26)', color: 'white', border: "none"}}>Nhập đơn đã
                            đóng</Button>
                        {/*<Button type={"danger"} icon={<ScanOutlined/>} onClick={() => showModalScanCode('pending')}*/}
                        {/*        style={{color: 'white', border: "none"}}>Nhập đơn chưa đóng</Button>*/}
                    </Space>
                </Col>
            </Row>
            <br/>

            <div className="card-container">
                <Row gutter={12}>
                    <Col span={12}>

                        <Table
                            title={() => 'ĐÃ GỬI HÀNG' + ' (' + data_shipped?.length + ')'}
                            bordered
                            rowKey={(record) => record._id}
                            pagination={false}
                            loading={loading}
                            columns={columns}
                            dataSource={data_shipped}
                            onChange={handleTableChange}
                        />
                    </Col>
                    <Col span={12}>
                        <Table
                            title={() => 'CHỜ LẤY' + ' (' + data_not_shipped.length + ')'}
                            bordered
                            rowKey={(record) => record._id}
                            pagination={false}
                            loading={loading}
                            columns={columns}
                            dataSource={data_not_shipped}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>

            </div>

        </div>
    </div>
}

export default PackedOrderPageDetail;