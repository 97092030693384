import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Input,
    List,
    Modal,
    notification,
    Row,
    Space,
    Spin,
    Steps,
    Table,
    Tabs,
    Tag
} from 'antd';
import {
    BarcodeOutlined,
    CheckCircleOutlined,
    PlusOutlined,
    PrinterOutlined,
    RedoOutlined,
    SearchOutlined
} from "@ant-design/icons";
import packedOrderService from '../../services/packed-order.service';
import './style.css'
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import _ from "underscore";
import soundWarning from "../../assets/beep-warning.mp3";
import soundSuccess from "../../assets/success.mp3";

const {TabPane} = Tabs;
const {Step} = Steps;
const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'green',
    'exists': 'gray',
    'exists-packed': 'red',
    'failed': 'red',
}
const columns = [{
    title: 'MÃ ĐƠN',
    dataIndex: 'order_id',
    key: 'order_id',
    render: (text, item) => {
        return <a onClick={() => {
            // copy to clipboard
            navigator.clipboard.writeText(text).then(function () {
                notification['success']({
                    message: 'Thông báo',
                    description: 'Đã copy mã đơn hàng'
                });
            }, function (err) {
                notification['error']({
                    message: 'Thông báo',
                    description: 'Đã xảy ra lỗi'
                });
            });
        }}>{text}</a>
    },
}, {
    title: 'NGUỒN ĐƠN', dataIndex: 'order_sources_name', key: 'order_sources_name', render: (text) => <a>{text}</a>,
}, {
    title: 'DVVC', dataIndex: 'short_name_partner', key: 'short_name_partner',
},
    {
        title: 'TRẠNG THÁI', dataIndex: 'status_readable', key: 'status_readable', render: (text) => {
            return <Tag color={'green'}>{text}</Tag>
        },
    },
    {
        title: 'IN', dataIndex: 'printed', key: 'printed', render: (text) => {
            return text ? <Tag color={'green'}>Đã in</Tag> : <Tag color={'red'}>Chưa in</Tag>
        }
    },
    {
        title: 'ĐÓNG GÓI', dataIndex: 'packed', key: 'packed', render: (text) => {
            return text ? <Tag color={'green'}>Đã đóng gói</Tag> : <Tag color={'red'}>Chưa đóng gói</Tag>
        }
    },
    {
        title: 'LẤY HÀNG', dataIndex: 'shipped', key: 'shipped', render: (text) => {
            return text ? <Tag color={'green'}>Đã giao hàng</Tag> : <Tag color={'red'}>Chưa giao hàng</Tag>
        }
    },
    {
        title: 'NGÀY TẠO', dataIndex: 'created_at_format', key: 'created_at_format'
    },
    {
        title: 'NGÀY CẬP NHẬT', dataIndex: 'updated_at_format', key: 'updated_at_format'
    },
];

const pageSize = 10;

function SolutionOutlined() {
    return null;
}

const OrdersPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [isModalSearchVisible, setIsModalSearchVisible] = useState(false);
    const [isModalListOrderOpen, setIsModalListOrderOpen] = useState(false);
    const [searchingOrder, setSearchingOrder] = useState(false);
    const [dataOrder, setDataOrder] = useState({});
    const [partner, setPartner] = useState('');
    const [filters, setFilters] = useState({});
    const list_order_sources = ['shopee', 'tiktok', 'facebook', 'unknown'];
    const list_partner = ['Giao hàng tiết kiệm', 'Giao hàng nhanh', 'J&T', "Shopee Xpress"];

    const [summaryOrder, setSummaryOrder] = useState({
        total_order: 0,
        "total_new_order": 0,
        "total_packed_today": 0,
        "total_shipped_today": 0,
        "total_not_printed": 0,
        "total_not_packed": 0,
        "total_not_shipped": 0,
        total_printed_not_packed: 0,
        total_packed_not_shipped: 0,
        total_shipped: 0,
        total_cancel: 0,
    });
    const [list_order_id, setListOrderId] = useState([]);
    const [isModalScanCodeVisible, setIsModalScanCodeVisible] = useState(false);
    const [currentStatusScan, setCurrentStatusScan] = useState('none'); // ['none', 'new', 'exists', 'updated'
    const [listOrderScanned, setListOrderScanned] = useState([]);
    const inputScanOrderRef = React.createRef();
    const [currentIdOrder, setCurrentIdOrder] = useState('');
    const [onAddPrintedOrder, setOnAddPrintedOrder] = useState(false);
    const audioWarning = new Audio(soundWarning);
    audioWarning.loop = false;
    const audioSuccess = new Audio(soundSuccess);
    audioSuccess.loop = false;
    const showModalScanCode = (status) => {
        setCurrentStatusScan(status)
        setIsModalScanCodeVisible(true,);
        setListOrderScanned([]);
    };
    const handleOkOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const handleCancelOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const inputSearchRef = React.createRef();
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'ĐÓNG ĐƠN'});
    }, [])

    const loadData = () => {
        packedOrderService.getSummaryOrder()
            .then(res => res.data)
            .then((res) => {
                setSummaryOrder(res);
            })
        queryOrders({
            filter, pagination
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const onScanOrder = () => {
        let value = inputScanOrderRef.current.input.value;
        if (value.trim().length === 0) {
            return;
        }
        //check exists

        addOrder(value)
        inputScanOrderRef.current.input.focus();
        setCurrentIdOrder('')
    };

    const addOrder = (value) => {
        console.log('addOrder', value)
        value = value.trim();
        let order = _.find(listOrderScanned, (item) => {
            return item.order_id === value;
        })
        if (order) {
            notification['error']({
                message: 'Thông báo',
                description: `Đơn hàng: ${value} đã tồn tại`
            });
            audioWarning.play();
            return;
        }
        let status = '';
        let message = '';
        setOnAddPrintedOrder(true);
        packedOrderService.printedOrder(value)
            .then(res => res.data)
            .then((res) => {
                notification['success']({
                    message: 'Thông báo',
                    description: `Đã thêm đơn hàng: ${value} vào danh sách`
                })
                audioSuccess.play();
                status = 'new';
                message = 'Đã thêm';
            })
            .catch((err) => {
                status = 'failed';
                message = err.response.data.message;
                console.log('err', err)
                notification['error']({
                    message: 'Thông báo',
                    description: message
                });
                audioWarning.play();

            })
            .finally(() => {
                setOnAddPrintedOrder(false);
                let data = [
                    ...listOrderScanned,
                    {
                        order_id: value,
                        status: status,
                        message: message
                    }
                ];
                data = _.uniq(data, order => order.order_id);
                setListOrderScanned(data);
            })
    }
    const onSearchOrder = (order_id) => {
        if (order_id.trim().length >= 1) {
            setSearchingOrder(true)
            packedOrderService.searchOrder(order_id)
                .then(res => res.data)
                .then((res) => {
                    console.log('res', res)
                    setDataOrder(res);
                })
                .catch((err) => {
                    setDataOrder({});
                })
                .finally(() => {
                    setSearchingOrder(false)
                });
        } else {
        }
    };

    const queryOrders = (params, cb) => {
        setLoading(true);
        let {filter, pagination} = params;
        if (filter.hasOwnProperty('partner_name') && filter.partner_name === '') {
            delete filter.partner_name;
        }
        if (filter.hasOwnProperty('order_sources_name') && filter.order_sources_name === '') {
            delete filter.order_sources_name;
        }
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current, limit: pagination.pageSize
        };
        packedOrderService.queryOrders(filter, options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.docs);
                setPagination({
                    ...pagination, total: res.totalDocs
                })
            })
            .finally(() => {
                if (cb) {
                    cb();
                }
            })
    };

    const handleOkOnModalListOrder = () => {
        setIsModalListOrderOpen(false);
    }

    const handleCancelOnModalListOrder = () => {
        setIsModalListOrderOpen(false);
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        queryOrders({
            filter, pagination: newPagination
        })
    };

    const onNewSessionPackedOrder = () => {
        packedOrderService.createPackedOrder()
            .then(res => res.data)
            .then((res) => {
                queryOrders({
                    filter, pagination
                })
                return window.location.href = '/packed-order/' + res._id;
            })
    }

    function handleOkOnModalSearch() {
        onSearchOrder(inputSearchRef.current.input.value);
    }

    function handleCancelOnModalSearch() {
        setIsModalSearchVisible(false);
    }

    function showModalSearch() {
        setIsModalSearchVisible(true);
    }

    function getListOrderByStatus(status) {
        return status.includes('all') ? listOrderScanned : listOrderScanned.filter(item => status.includes(item.status));
    }

    function copyListOrder(data) {
        let list_order_id = _.map(data, (item) => {
            return item.order_id;
        })
        let text = list_order_id.join('\n');
        navigator.clipboard.writeText(text).then(function () {
            notification['success']({
                message: 'Thông báo',
                description: 'Đã copy danh sách đơn hàng'
            });
        }, function (err) {
            notification['error']({
                message: 'Thông báo',
                description: 'Đã xảy ra lỗi'
            });
        });
    }

    const onChangeTab = (key) => {
        setPartner('')
        let filter = {};
        let pagination = {
            current: 1, pageSize: pageSize,
        }
        switch (key) {
            case 'all':
                filter = {};
                break;
            case 'not_printed':
                filter = {printed: false, status: {$in: ['0', '-2', '17', '11', '1', '8', '9']}};
                break;
            case 'printed':
                filter = {printed: true, packed: false, status: {$in: ['0', '-2', '17', '11', '1', '8', '9']}};
                break;
            case 'packed_not_shipped':
                filter = {packed: true, shipped: false, status: {$in: ['0', '-2', '17', '11', '1', '8', '9']}};
                break;
            case 'shipped':
                filter = {shipped: true, status: 2};
                break;
            case 'cancel':
                filter = {status: '6'};
                break;
            default:
                filter = {};
                break;
        }
        queryOrders({
            filter, pagination
        })
    }

    function renderListOrderScanned(status) {
        let data = getListOrderByStatus(status);
        return <List
            style={{
                maxHeight: 400,
                overflowY: 'scroll',
                marginTop: 10
            }}
            header={<span>DANH SÁCH ĐƠN ({data.length}) <a
                onClick={() => copyListOrder(data)}>COPY DANH SÁCH ĐƠN</a></span>}
            bordered={true}
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item key={item + index}>
                    <CheckCircleOutlined style={{color: colors_status_order_scanned[item.status]}}/> {item.order_id}
                    <Tag
                        color={colors_status_order_scanned[item.status]}
                        style={{float: 'right'}}>{item.message}</Tag>
                </List.Item>
            )}
        />
    }

    function onSelectPartner(value) {
        if (partner === value) {
            value = '';
            delete filter.partner_name;
            queryOrders({
                filter, pagination
            })
        }
        setPartner(value);
        queryOrders({
            filter: {
                ...filter,
                partner_name: value
            }, pagination

        })
    }

    function onSelectOrderSource(value) {
        if (filter.order_sources_name === value) {
            value = '';
            queryOrders({
                filter, pagination
            }, () => {

            })
        }
        queryOrders({
            filter: {
                ...filter,
                order_sources_name: value
            }, pagination

        })
    }

    return <div>
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Đóng hàng</Breadcrumb.Item>
        </Breadcrumb>


        <Modal title="Danh sách đơn" visible={isModalListOrderOpen} onOk={handleOkOnModalListOrder}
               width={700}
               onCancel={handleCancelOnModalListOrder}>
            <List
                style={{
                    maxHeight: 400,
                    overflowY: 'scroll',
                    marginTop: 10
                }}
                bordered={true}
                dataSource={list_order_id}
                renderItem={(item, index) => (
                    <List.Item key={item + index}>
                        <CheckCircleOutlined
                            style={{color: '#52c41a'}}/> {item.order_id} | {item.status_source_readable} <Tag
                        onClick={() => {
                            onSearchOrder(item.order_id);
                            setIsModalSearchVisible(true);
                        }}
                        color={'#87d068'}
                        style={{float: 'right', cursor: 'pointer'}}><SearchOutlined/></Tag>
                        {
                            item.distance_to_now === 0 ? <Tag color={'red'} style={{float: 'right'}}>Hôm nay</Tag> :
                                <Tag color={'red'} style={{float: 'right'}}>{item.distance_to_now} ngày trước
                                    ({item.first_created_at})</Tag>
                        }

                    </List.Item>
                )}
            />
        </Modal>

        <Modal title="Tìm kiếm đơn hàng" visible={isModalSearchVisible} onOk={handleOkOnModalSearch}
               onCancel={handleCancelOnModalSearch} okText={'Tìm kiếm'} cancelText={'Hủy'}
               confirmLoading={searchingOrder}
        >
            <Input
                size="large"
                placeholder="Nhập mã vận đơn"
                prefix={<SearchOutlined/>}
                ref={inputSearchRef}
                onPressEnter={() => onSearchOrder(inputSearchRef.current.input.value)}
            />
            <Divider/>
            {Object.keys(dataOrder).length === 0 ?
                <div style={{textAlign: 'center'}}>Không có data</div> :
                <>
                    <Row>
                        <Col span={8}>
                            <b>Ngày tạo</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.created_at_format}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <b>Mã vận đơn</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.order_id}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Nguồn đơn</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.order_sources_name}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Đơn vị vận chuyển</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.partner_name}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái POS</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.status_readable}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái in</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.printed ? <Tag color={'green'}>{dataOrder.printed_by} đã in
                                    lúc {dataOrder.printed_at_format}</Tag> :
                                <Tag color={'red'}>Chưa in</Tag>}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái đóng gói</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.packed ?
                                <Tag color={'green'}>{dataOrder.packed_by} đã đóng gói
                                    lúc {dataOrder.packed_at_format}</Tag> :
                                <Tag color={'red'}>Chưa đóng gói</Tag>}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái giao hàng</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.shipped ?
                                <Tag color={'green'}>Đã giao hàng lúc {dataOrder.shipped_at_format}</Tag> :
                                <Tag color={'red'}>Chưa giao hàng</Tag>}
                        </Col>
                    </Row>

                </>

            }

        </Modal>

        <div
            className="site-layout-background"
            style={{
                padding: 24, minHeight: 360,
            }}
        >

            <Modal title={'Quét đơn đã in'}
                   width={800}
                   visible={isModalScanCodeVisible}
                   onOk={handleOkOnModalScanCode}
                   onCancel={handleCancelOnModalScanCode}
                   okText={'XỬ LÝ ĐƠN'}
                   footer={[
                       <Button onClick={handleCancelOnModalScanCode}>Đóng</Button>,
                   ]}
            >
                <Spin spinning={onAddPrintedOrder} delay={500}>
                    <Space direction={'vertical'} style={{width: '100%'}}>
                        <Row align={'center'}>
                            <Input
                                size="large"
                                placeholder="quét mã vận đơn"
                                prefix={<BarcodeOutlined/>}
                                ref={inputScanOrderRef}
                                value={currentIdOrder}
                                onPressEnter={onScanOrder}
                                onChange={(e) => setCurrentIdOrder(e.target.value)}
                            />
                        </Row>
                        <Tabs>
                            <Tabs.TabPane tab={<>TẤT CẢ <Tag>{getListOrderByStatus(['all']).length}</Tag></>}
                                          key="item-1">
                                {renderListOrderScanned(['all'])}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={<>THÀNH CÔNG <Tag
                                    color="#87d068">{getListOrderByStatus(['new', 'exists']).length}</Tag></>}
                                key="item-2">
                                {renderListOrderScanned(['new', 'exists'])}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={<>THẤT BẠI <Tag color="#f50">{getListOrderByStatus(['failed']).length}</Tag></>}
                                key="item-3">
                                {renderListOrderScanned(['failed'])}
                            </Tabs.TabPane>
                        </Tabs>

                    </Space>
                </Spin>
            </Modal>

            <Row style={{}}>
                <Col>
                    <Space>
                        <Button type={"primary"} icon={<PrinterOutlined/>} onClick={showModalScanCode}
                                style={{background: 'rgb(255,147,24)', color: 'white', border: "none"}}>Nhập đơn
                            in</Button>
                        <Button type={"primary"} icon={<PlusOutlined/>} onClick={onNewSessionPackedOrder}
                                style={{background: 'rgb(82, 196, 26)', color: 'white', border: "none"}}>Tạo phiên
                            mới</Button>
                        <Button type={"primary"} icon={<SearchOutlined/>} onClick={showModalSearch}
                                style={{color: 'white', border: "none"}}>Tìm đơn</Button>
                        <Button type={"primary"} icon={<RedoOutlined/>} onClick={loadData}
                                style={{color: 'white', border: "none", background: 'rgb(26,145,196)'}}>Tải lại</Button>
                    </Space>
                </Col>
            </Row>
            <br/>

            <div className="card-container">
                <Tabs onChange={onChangeTab} type="card">
                    <TabPane tab={<span>Tât cả đơn <Tag color="geekblue">{summaryOrder.total_order}</Tag></span>}
                             key="all">
                    </TabPane>
                    <TabPane tab={<span>Chưa in <Tag color="geekblue">{summaryOrder.total_not_printed}</Tag></span>}
                             key="not_printed">
                    </TabPane>
                    <TabPane
                        tab={<span>Đã in <Tag color="geekblue">{summaryOrder.total_printed_not_packed}</Tag></span>}
                        key="printed">
                    </TabPane>
                    <TabPane
                        tab={<span>Đã đóng gói <Tag
                            color="geekblue">{summaryOrder.total_packed_not_shipped}</Tag></span>}
                        key="packed_not_shipped">
                    </TabPane>
                    <TabPane tab={<span>Đã gửi hàng <Tag
                        color="geekblue">{summaryOrder.total_shipped}</Tag></span>} key="shipped">
                    </TabPane>
                    <TabPane tab={<span>Đã huỷ <Tag
                        color="geekblue">{summaryOrder.total_cancel}</Tag></span>} key="cancel">
                    </TabPane>
                </Tabs>
                <Row>
                    <Col>
                        <Space>
                            {
                                list_order_sources.map((item, index) => {
                                    return <Tag
                                        icon={filter.order_sources_name === item ? <CheckCircleOutlined/> : null}
                                        color="processing"
                                        style={{
                                            cursor: 'pointer'
                                        }} onClick={() => onSelectOrderSource(item)}>{item}</Tag>
                                })
                            }
                            <Divider type={"vertical"}/>
                            {
                                list_partner.map((item, index) => {
                                    return <Tag icon={partner === item ? <CheckCircleOutlined/> : null}
                                                color="processing"
                                                style={{
                                                    cursor: 'pointer'
                                                }} onClick={() => onSelectPartner(item)}>{item}</Tag>
                                })
                            }
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Table
                    rowKey={(record) => record.order_id}
                    pagination={pagination}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                    footer={() => {
                        return <Row>
                            <Col span={8}>
                                <b>Tổng đơn: {pagination.total}</b>
                            </Col>
                        </Row>
                    }}
                />
            </div>

        </div>
    </div>
}

export default OrdersPage;