import {Breadcrumb, Table, Tag} from "antd";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../recoil/atom/page.atom";
import {useEffect, useState} from "react";
import FormSearch from "../components/shared/FormSearch";
import orderService from "../services/order.service";

const colors_status = {
    'pending': 'red', 'returned': 'green'
}

const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'cyan',
    'exists': 'volcano',
    'updated': 'blue',
}
const pageSize = 10;
const columns = [
    {
        title: 'NGƯỜI NẠP', dataIndex: 'order_id', key: 'order_id', render: (text) => <a>{text}</a>,
    },
    {
        title: 'MÃ THẺ', dataIndex: 'order_id', key: 'order_id', render: (text) => <a>{text}</a>,
    },
    {
        title: 'SERIAL', dataIndex: 'created_by', key: 'created_by', render: (text) => <a>{text}</a>,
    },
    {
        title: 'NHÀ MẠNG', dataIndex: 'created_at_format', key: 'created_at_format',
    },
    {
        title: 'MỆNH GIÁ', dataIndex: 'updated_at_format', key: 'updated_at_format',
    },
    {
        title: 'THỜI GIAN', dataIndex: 'updated_at_format', key: 'updated_at_format',
    },
    {
        title: 'TRẠNG THÁI',
        key: 'status',
        dataIndex: 'status',
        render: (_, {status, status_readable}) => {
            return <Tag color={colors_status[status]} key={status}>
                {status_readable.toUpperCase()}
            </Tag>
        },
    },

];
const HistoryPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(false);
    const onSearch = (keyword) => {
        console.log('onSearch', keyword)
        //     traceOrderService.getListBoard(keyword)
        //         .then((res) => {
        //             setListBoardState(res.data)
        //         })
        //         .finally(() => {
        //             setIsLoadingBoard(false);
        //         })
    }


    const queryOrderReturns = (params) => {
        setLoading(true);

        let {filter, pagination} = params;
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current, limit: pagination.pageSize
        };
        orderService.queryOrderReturns(filter, options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.docs);
                setPagination({
                    ...pagination,
                    total: res.totalDocs
                })
            })
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        queryOrderReturns({
            filter, pagination: newPagination
        })
    };

    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'LỊCH SỬ NẠP THẺ'});
    }, [])
    return (
        <div>
            <FormSearch
                placeholder={'Mã thẻ / serial'}
                onSearch={onSearch}
            />
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>History</Breadcrumb.Item>
            </Breadcrumb>
            <Table
                rowKey={(record) => record.order_id}
                pagination={pagination}
                loading={loading}
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
            />
        </div>
    )
};

export default HistoryPage;