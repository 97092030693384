import React, {useState} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {Avatar, Button, Divider, List, Popconfirm, Space} from "antd";
import moment from "moment";
import {DeleteOutlined, MessageOutlined} from "@ant-design/icons";
import traceOrderService from "../../services/trace-order.service";
import {openNotificationWithIcon} from "../../utils/common";

const IconText = ({icon, text}) => (
    <Space size={4}>
        {React.createElement(icon)}
        {text}
    </Space>
);

function Task({item, index, showModalDetailTask, onDeleteItem}) {
    const [isHover, setIsHover] = useState(false);
    const confirm = (e) => {
        e.stopPropagation();
        console.log(e);
        onDeleteItem(item.id)

        traceOrderService.deleteItem(item.id)
            .then(res => {
                console.log('res', res)
                openNotificationWithIcon('success', 'Xoá thành công')
                onDeleteItem(item.id)
            })
            .catch(err => {
                console.log('err', err)
                openNotificationWithIcon('error', 'Có lỗi trong quá trình xoá')

            })
    };

    const cancel = (e) => {
        e.stopPropagation()
        console.log(e);
        // message.error('Click on No');
    };
    const getDescription = () => {
        return <div style={{position: "relative"}}><Space
            style={{fontSize: 12}}>{moment(item.created_at).format('HH:mm:ss DD/MM/YYYY')}
            {item.comments.length > 0 ? <><Divider type="vertical"/><IconText
                icon={MessageOutlined} text={item.comments.length} key="list-vertical-message"/></> : ''}
        </Space>{isHover ?
            <Popconfirm
                title="Bạn có chắc chắn muốn xoá?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
                onClick={(e) => e.stopPropagation()}
            ><Button style={{position: 'absolute', right: 3, border: 'none', top: -5}} icon={<DeleteOutlined/>} danger/>
            </Popconfirm> : ''}
        </div>
    }

    const getTitle = () => {
        return item.name.length > 0 ? item.name : 'Untitled';
    }
    return <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
            <div>
                <List.Item key={item.id}
                           ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                           onClick={() => showModalDetailTask(item)}
                           onMouseEnter={() => setIsHover(true)}
                           onMouseLeave={() => setIsHover(false)}
                >
                    <List.Item.Meta
                        style={{wordBreak: 'break-word'}}
                        avatar={<Avatar src={item.created_by.avatar}/>}
                        title={getTitle()}
                        description={getDescription()}
                    />
                </List.Item>
            </div>
        )}
    </Draggable>;
}

export default Task;