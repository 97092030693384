import axios from 'axios';
import userService from "../services/user.service";

axios.interceptors.request.use(
    config => {
        const token = userService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    });

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log('call api error: ', error)
    const originalRequest = error.config;
    console.log('originalRequest', originalRequest)
    if (originalRequest.url.includes('/user/refresh-token')) {
        return Promise.reject(error);
    }
    if (error.response.status === 401 || error.response.status === 403) {
        const refresh_token = userService.getRefreshToken();
        return userService.refreshToken(refresh_token)
            .then(res => {
                console.log('get refresh token successfully:', res);
                const {user} = res.data;
                userService.setAccessToken(user.access_token);
                userService.setRefreshToken(user.refresh_token);
                return axios(originalRequest);
            })
            .catch(err => {
                console.log('failed on get refresh token:', err);
                return Promise.reject(err);
            })
    }
    return Promise.reject(error);
});

export default axios;