import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Badge, Col, Comment, Divider, Dropdown, Layout, List, Menu, Popover, Row, Space, Tooltip} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    BarcodeOutlined,
    BellOutlined,
    BookOutlined,
    CalendarOutlined,
    CarOutlined,
    CodeSandboxOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    HistoryOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    ShoppingCartOutlined
} from "@ant-design/icons";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {userProfileAtom} from "../../recoil/atom/user.atom";
import userService from "../../services/user.service";
import {pageInfoAtom, searchBarAtom} from "../../recoil/atom/page.atom";
import moment from "moment";

const {Header, Content, Footer, Sider} = Layout;

const menu = (<Menu
    items={[{
        key: '1', label: (<a target="_blank" rel="noopener noreferrer" href="/">
            <Space>
                <SettingOutlined/>
                Đổi mật khẩu
            </Space>
        </a>),
    }, {
        key: '2', label: (<a target="_blank" rel="noopener noreferrer" onClick={userService.logout}>
            <Space>
                <LogoutOutlined/>
                Đăng xuất
            </Space>
        </a>),
    }]}
/>);

function getItem(label, key, icon, children) {
    return {
        key, icon, children, label,
    };
}

const items = [
    getItem('Dashboard', 'dashboard',
        <DashboardOutlined/>),
    // getItem('Đặt hàng', 'purchase-menu',  <CodeSandboxOutlined/>,
    //     [getItem('Đặt hàng', 'purchase'),
    //         getItem('Lịch sử nhập', 'purchase-history'),
    //         getItem('Công nợ', 'payment-history'),],),
    // getItem('Nạp thẻ', 'card',
    //     <CodeSandboxOutlined/>,
    //     [getItem('Đóng hàng', 'packed-order'),
    //         getItem('Lịch sử nhập', 'purchase-history'),
    //         getItem('Công nợ', 'payment-history'),],)
    ,
    getItem('Đơn hàng', 'orders', <CarOutlined
    />),
    getItem('Đóng hàng', 'packed-order', <CodeSandboxOutlined/>),
    getItem('Nạp thẻ', 'card', <CreditCardOutlined/>),
    getItem('Lịch sử nạp thẻ', 'history', <HistoryOutlined/>),
    getItem('Xử lý đơn', 'trace-order', <ShoppingCartOutlined/>),
    getItem('Đơn hoàn', 'return-order',
        <BarcodeOutlined/>), getItem('Lịch làm việc', 'calender', <CalendarOutlined/>), getItem('Tài liệu', 'document',
        <BookOutlined/>),];


const MasterLayout = (props) => {
    const {children} = props;
    const searchBarRef = useRef();
    const userProfileState = useRecoilValue(userProfileAtom);
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const searchBarState = useSetRecoilState(searchBarAtom);
    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.substring(1);

    const [visibleNotification, setVisibleNotification] = useState(false);

    const hide = () => {
        setVisibleNotification(false);
    };

    const handleVisibleChange = (newVisible) => {
        setVisibleNotification(newVisible);
    };

    const notifications = [{
        author: 'Tien Dinh', avatar: 'https://joeschmoe.io/api/v1/random', content: (<p>
            Test thông báo 1
        </p>), datetime: (<Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment().subtract(1, 'days').fromNow()}</span>
        </Tooltip>),
    }, {
        author: 'Han Solo', avatar: 'https://joeschmoe.io/api/v1/random', content: (<p>
            Test thông báo 2
        </p>), datetime: (<Tooltip title={moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment().subtract(2, 'days').fromNow()}</span>
        </Tooltip>),
    },];

    const getPositionSearchBar = () => {
        setTimeout(() => {
            searchBarState({
                x: searchBarRef.current.offsetLeft, y: 15, width: searchBarRef.current.offsetWidth
            })
        }, 200);
    }
    useEffect(() => {
        getPositionSearchBar();
    }, [])
    return (<Layout
        style={{
            minHeight: '100vh',
        }}
    >
        <Sider breakpoint="lg"
            // collapsedWidth="0"
            // collapsible
               collapsed={collapsed} onCollapse={(value) => {
            getPositionSearchBar();
            setCollapsed(value);
        }}>
            <div className="logo"/>
            <Menu theme="dark" defaultSelectedKeys={['card']} selectedKeys={[pathname]} mode="inline"
                  items={items}
                  onClick={({key}) => {
                      navigate(key);
                  }}/>
        </Sider>
        <Layout className="site-layout">
            <Header
                className="site-layout-background"
                style={{
                    padding: '0px 16px'
                }}
            >
                <Row justify={'end'} align={'middle'}>
                    <Col>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger', style: {
                            fontSize: 20
                        }, onClick: () => {
                            getPositionSearchBar();
                            setCollapsed(!collapsed)
                        },
                    })}
                    </Col>
                    <Col>
                            <span style={{
                                marginLeft: 10, fontSize: 19, marginBottom: 8, display: 'inline-block'
                            }}>{pageInfoState.title}</span>
                        <Divider style={{marginRight: 15, marginLeft: 15}} type="vertical"/>
                    </Col>
                    <Col flex={1} id={'search_bar'} ref={searchBarRef}>

                    </Col>
                    <Col>

                        <Space size={'small'}>
                            <Divider style={{marginRight: 15, marginLeft: 15}} type="vertical"/>

                            <Popover
                                content={<List
                                    className="comment-list"
                                    itemLayout="horizontal"
                                    dataSource={notifications}
                                    style={{width: '500px'}}
                                    renderItem={item => (<li>
                                        <Comment
                                            actions={item.actions}
                                            author={item.author}
                                            avatar={item.avatar}
                                            content={item.content}
                                            datetime={item.datetime}
                                            style={{padding: 0}}
                                        />
                                        <Divider style={{padding: 0, margin: 0}}/>
                                    </li>)}
                                />}
                                title="Thông báo"
                                trigger="click"
                                visible={visibleNotification}
                                onVisibleChange={handleVisibleChange}
                            >
                                <Badge count={5} size={'default'} style={{cursor: "pointer"}}>
                                    <BellOutlined style={{fontSize: 24, cursor: "pointer"}}/>
                                </Badge>
                            </Popover>
                            <Divider type="vertical"/>
                            <Dropdown overlay={menu}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Avatar src={userProfileState.avatar}/> {userProfileState.full_name}
                                </a>
                            </Dropdown>
                        </Space>
                    </Col>
                </Row>
            </Header>
            <Content
                style={{
                    margin: '0 16px',
                }}
            >
                {children}
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >
                SIM Boutique ©2022 Created by Tien Dinh
            </Footer>
        </Layout>
    </Layout>);
};

export default MasterLayout;