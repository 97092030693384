import React from 'react';
import {Button, DatePicker, Form, Select} from 'antd';
import dayjs from "dayjs";
import moment from "moment";
import {getRangeTime} from "../../utils/common";

const {RangePicker} = DatePicker;

const FormFilterChart = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        let data_filter = [];
        let group = 'days';
        for (let [key, value] of Object.entries(values)) {
            if (value) {
                if (key === 'group_by') {
                    group = value;
                } else {
                    value = key === 'yearMonthDayUTC' ? {
                        "$gte": dayjs(value[0]).format('YYYY-MM-DD'),
                        "$lte": dayjs(value[1]).format('YYYY-MM-DD')
                    } : value;
                    data_filter.push({[key]: value})
                }
            }
        }
        if (!data_filter.find(item => item.hasOwnProperty('yearMonthDayUTC'))) {
            data_filter.push({
                "yearMonthDayUTC": {
                    "$gte": moment().startOf('month').format('YYYY-MM-DD'),
                    "$lte": moment().format('YYYY-MM-DD')
                }
            })
        }
        console.log(getRangeTime(
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            'days'
        ))
        props.onSubmit(data_filter, group);
    };

    return (
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
            <Form.Item
                name="group_by"
            >
                <Select
                    style={{
                        width: 150,
                    }}
                    placeholder="Nhóm theo"
                    defaultValue={'days'}
                    options={[
                        {label: 'Ngày', value: 'days'},
                        {label: 'Tháng', value: 'months'},
                        {label: 'Năm', value: 'years'},
                    ]}
                />
            </Form.Item>
            <Form.Item
                name="yearMonthDayUTC"
            >
                <RangePicker
                    style={{
                        width: 280,
                    }}
                    placeholder={['Bắt đầu', 'Kết thúc']}
                    format={'DD-MM-YYYY'}
                    defaultValue={[moment().startOf('month'), moment()]}
                />
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        ÁP DỤNG
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};
export default FormFilterChart;