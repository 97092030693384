import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const getListWorkSchedule = (filter) => {
    return axios.get(`${API_ENDPOINT}/v1/work-schedule`, {
        params: filter
    })
}

const addNewWorkSchedule = (payload) => {
    return axios.post(`${API_ENDPOINT}/v1/work-schedule`, payload)
}

const getReportByStaff = (params) => {
    return axios.get(`${API_ENDPOINT}/v1/work-schedule/report`, {params})
}

export default {
    getListWorkSchedule,
    addNewWorkSchedule,
    getReportByStaff
}