import React from 'react';
import {Button, Form, Input, Modal, Select, Tag} from 'antd';
import {CheckSquareOutlined, CloseSquareOutlined, CommentOutlined} from "@ant-design/icons";

const {Option} = Select;
const {TextArea} = Input;


const ModalDetail = (props) => {
    const [form] = Form.useForm();
    const renderNote = (note) => {
        if (note) {
            return <><CommentOutlined style={{color: '#40a9ff'}}/> {note}</>
        } else {
            return <></>
        }
    }
    return <Modal title={`Lịch làm việc ngày ${props.dateSelected}`}
                  visible={props.visible}
                  onOk={form.submit}
                  onCancel={props.onCancel}
                  footer={[
                      <Button onClick={props.onCancel}>Đóng</Button>,
                  ]}
    >
        <div>
            {props.listData.map((item) => {
                return <ul key={item._id} className="events">
                    <Tag style={{width: '100%', margin: 0}} color="volcano">{item.staff.full_name}</Tag>
                    {
                        item.shifts.map((shift, index) => {
                            return shift.status
                                ? <li key={index}><CheckSquareOutlined
                                    style={{color: '#52c41a'}}/> {shift.shift_label}
                                </li>
                                : <li key={index}><CloseSquareOutlined
                                    style={{color: '#eb2f96'}}/> {shift.shift_label}
                                </li>

                        })
                    }
                    <li> {renderNote(item.note)}</li>
                </ul>
            })}
        </div>

    </Modal>


}

export default ModalDetail;