import {notification} from 'antd';
import Moment from 'moment';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);

const openNotificationWithIcon = (type, description, message) => {
    message = message ?? 'Thông báo';
    notification[type]({
        message,
        description
    });
};

const removeItemByIndex = (index, array) => {
    delete array[index];
    return array.filter(item => item);
}

const debounce = (callback, wait = 500) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}

const formatCurrency = n => String(n).replace(/(.)(?=(\d{3})+$)/g, '$1.') + 'đ';

// start: YYYY-MM-DD
// end: 2021-01-31
// type: days, months, years
function getRangeTime(startDate, endDate, type) {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    const range = moment.range(start, end);
    let rangeTime = [];
    let format = {
        days: 'DD-MM-YYYY',
        months: 'MM-YYYY',
        years: 'YYYY'
    };
    for (let month of range.by(type)) {
        rangeTime.push(month.format(format[type]));
    }
    return rangeTime;
}

export {
    openNotificationWithIcon,
    removeItemByIndex,
    debounce,
    formatCurrency,
    getRangeTime
}