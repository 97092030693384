import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, notification, Row, Spin} from 'antd';
import userService from "../../services/user.service";
import './style.css'
import {useState} from "react";

const LoginPage = (props) => {
    // userService.logout();
    const [isLoading, setIsLoading] = useState(false);
    const onFinish = (values) => {
        setIsLoading(true);
        userService.login(values.username, values.password)
            .then(res => {
                const {user} = res.data;
                userService.setAccessToken(user.access_token);
                userService.setRefreshToken(user.refresh_token);
                window.location.href = '/';
            })
            .catch(err => {
                console.log('userService.login err', err)
                let message = err?.response?.data?.message || 'Đăng nhập thất bại';
                notification['error']({
                    message: 'Thông báo',
                    description: message
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return <Row justify={'center'} align={'middle'} style={{height: '100vh'}}><Col><Spin size="large"/></Col></Row>
    }
    return (

        <Row align={'middle'} justify={'center'} style={{height: '80vh'}}>
            <Col>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Tên đăng nhập"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Nhớ đăng nhập</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                            Quên mật khâu
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            ĐĂNG NHẬP
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default LoginPage;