import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const queryOrderReturns = (filters, options) => {
    console.log('options', options)
    return axios.get(`${API_ENDPOINT}/v1/order/query`, {
        params: {...options, ...filters}
    })
}

const addNewOrderReturn = (body) => {
    return axios.post(`${API_ENDPOINT}/v1/order/add`, body)
}

const updateBulkOrderReturns = (list_id, updateProgress) => {
    return axios.post(`${API_ENDPOINT}/v1/order/bulk-update`, {list_id}, {
        onDownloadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateProgress(percentCompleted)
        }
    })
}

const addBulkOrderReturns = (list_id, updateProgress) => {
    return axios.post(`${API_ENDPOINT}/v1/order/bulk-add`, {list_id}, {
        onDownloadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateProgress(percentCompleted)
        }
    })
}

export default {
    queryOrderReturns,
    updateBulkOrderReturns,
    addNewOrderReturn,
    addBulkOrderReturns
}