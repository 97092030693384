import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Divider,
    Input,
    List,
    Modal,
    notification,
    Row,
    Space,
    Spin,
    Steps,
    Table,
    Tabs,
    Tag
} from 'antd';
import {BarcodeOutlined, CheckCircleOutlined, PlusOutlined, PrinterOutlined, SearchOutlined} from "@ant-design/icons";
import packedOrderService from '../../services/packed-order.service';
import './style.css'
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import {Link} from "react-router-dom";
import _ from "underscore";
import soundWarning from "../../assets/beep-warning.mp3";
import soundSuccess from "../../assets/success.mp3";

const {Step} = Steps;
const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'green',
    'exists': 'gray',
    'exists-packed': 'red',
    'failed': 'red',
}
const columns = [{
    title: 'PHIÊN',
    dataIndex: 'name',
    key: 'name',
    render: (text, item) => <Link to={'/packed-order/' + item._id}>{text}</Link>,
}, {
    title: 'NGƯỜI TẠO', dataIndex: 'created_by', key: 'created_by', render: (text) => <a>{text}</a>,
}, {
    title: 'TỔNG ĐƠN', dataIndex: 'total_order', key: 'total_order', render: (text) => <a>{text}</a>,
}, {
    title: 'ĐÃ GỬI', dataIndex: 'total_shipped', key: 'total_shipped', render: (text) => <a>{text}</a>,
}, {
    title: 'CHƯA GỬI', dataIndex: 'total_not_shipped', key: 'total_not_shipped', render: (text) => <a>{text}</a>,
},];

const pageSize = 10;

function SolutionOutlined() {
    return null;
}

const PackedOrderPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [isModalSearchVisible, setIsModalSearchVisible] = useState(false);
    const [isModalListOrderOpen, setIsModalListOrderOpen] = useState(false);
    const [searchingOrder, setSearchingOrder] = useState(false);
    const [dataOrder, setDataOrder] = useState({});
    const [summaryOrder, setSummaryOrder] = useState({
        total_order: 0,
        "total_new_order": 0,
        "total_packed_today": 0,
        "total_shipped_today": 0,
        "total_not_printed": 0,
        "total_not_packed": 0,
        "total_not_shipped": 0,
        "total_printed_not_packed": 0,
    });
    const [list_order_id, setListOrderId] = useState([]);
    const [isModalScanCodeVisible, setIsModalScanCodeVisible] = useState(false);
    const [currentStatusScan, setCurrentStatusScan] = useState('none'); // ['none', 'new', 'exists', 'updated'
    const [listOrderScanned, setListOrderScanned] = useState([]);
    const inputScanOrderRef = React.createRef();
    const [currentIdOrder, setCurrentIdOrder] = useState('');
    const [onAddPrintedOrder, setOnAddPrintedOrder] = useState(false);
    const audioWarning = new Audio(soundWarning);
    audioWarning.loop = false;
    const audioSuccess = new Audio(soundSuccess);
    audioSuccess.loop = false;
    const showModalScanCode = (status) => {
        setCurrentStatusScan(status)
        setIsModalScanCodeVisible(true,);
        setListOrderScanned([]);
    };
    const handleOkOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const handleCancelOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const inputSearchRef = React.createRef();
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'ĐÓNG ĐƠN'});
    }, [])

    useEffect(() => {
        packedOrderService.getSummaryOrder()
            .then(res => res.data)
            .then((res) => {
                setSummaryOrder(res);
            })
    }, []);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const onScanOrder = () => {
        let value = inputScanOrderRef.current.input.value;
        if (value.trim().length === 0) {
            return;
        }
        //check exists

        addOrder(value)
        inputScanOrderRef.current.input.focus();
        setCurrentIdOrder('')
    };

    const addOrder = (value) => {
        console.log('addOrder', value)
        value = value.trim();
        let order = _.find(listOrderScanned, (item) => {
            return item.order_id === value;
        })
        if (order) {
            notification['error']({
                message: 'Thông báo',
                description: `Đơn hàng: ${value} đã tồn tại`
            });
            audioWarning.play();
            return;
        }
        let status = '';
        let message = '';
        setOnAddPrintedOrder(true);
        packedOrderService.printedOrder(value)
            .then(res => res.data)
            .then((res) => {
                notification['success']({
                    message: 'Thông báo',
                    description: `Đã thêm đơn hàng: ${value} vào danh sách`
                })
                audioSuccess.play();
                status = 'new';
                message = 'Đã thêm';
            })
            .catch((err) => {
                status = 'failed';
                message = err.response.data.message;
                console.log('err', err)
                notification['error']({
                    message: 'Thông báo',
                    description: message
                });
                audioWarning.play();

            })
            .finally(() => {
                setOnAddPrintedOrder(false);
                let data = [
                    ...listOrderScanned,
                    {
                        order_id: value,
                        status: status,
                        message: message
                    }
                ];
                data = _.uniq(data, order => order.order_id);
                setListOrderScanned(data);
            })
    }
    const onSearchOrder = (order_id) => {
        if (order_id.trim().length >= 1) {
            setSearchingOrder(true)
            packedOrderService.searchOrder(order_id)
                .then(res => res.data)
                .then((res) => {
                    console.log('res', res)
                    setDataOrder(res);
                })
                .catch((err) => {
                    setDataOrder({});
                })
                .finally(() => {
                    setSearchingOrder(false)
                });
        } else {
        }
    };

    const querySessions = (params) => {
        setLoading(true);
        let {filter, pagination} = params;
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current, limit: pagination.pageSize
        };
        packedOrderService.queryPackedOrders(filter, options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.docs);
                setPagination({
                    ...pagination, total: res.totalDocs
                })
            })
    };

    const showModalListOrder = (type) => {
        // let list_order_id = type === 'pending' ? orders_pending : orders_warning;
        // setListOrderId(list_order_id)
        // setIsModalListOrderOpen(true);
    }
    const handleOkOnModalListOrder = () => {
        setIsModalListOrderOpen(false);
    }

    const handleCancelOnModalListOrder = () => {
        setIsModalListOrderOpen(false);
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        querySessions({
            filter, pagination: newPagination
        })
    };

    const onNewSessionPackedOrder = () => {
        packedOrderService.createPackedOrder()
            .then(res => res.data)
            .then((res) => {
                querySessions({
                    filter, pagination
                })
                return window.location.href = '/packed-order/' + res._id;
            })
    }

    useEffect(() => {
        querySessions({
            filter, pagination
        })
    }, []);


    function handleOkOnModalSearch() {
        onSearchOrder(inputSearchRef.current.input.value);
    }

    function handleCancelOnModalSearch() {
        setIsModalSearchVisible(false);
    }

    function showModalSearch() {
        setIsModalSearchVisible(true);
    }

    function getListOrderByStatus(status) {
        return status.includes('all') ? listOrderScanned : listOrderScanned.filter(item => status.includes(item.status));
    }

    function copyListOrder(data) {
        let list_order_id = _.map(data, (item) => {
            return item.order_id;
        })
        let text = list_order_id.join('\n');
        navigator.clipboard.writeText(text).then(function () {
            notification['success']({
                message: 'Thông báo',
                description: 'Đã copy danh sách đơn hàng'
            });
        }, function (err) {
            notification['error']({
                message: 'Thông báo',
                description: 'Đã xảy ra lỗi'
            });
        });
    }

    const onRemoveItemScan = (index) => {
        let newListOrderScanned = [...listOrderScanned];
        let itemScanSelected = newListOrderScanned[index];
        delete newListOrderScanned[index];
        newListOrderScanned = newListOrderScanned.filter(item => item)
        setListOrderScanned(newListOrderScanned);

        notification['error']({
            message: 'Thông báo',
            description: `Đã bỏ đơn hàng: ${itemScanSelected.order_id} ra khỏi danh sách`
        });
    }


    function renderListOrderScanned(status) {
        let data = getListOrderByStatus(status);
        return <List
            style={{
                maxHeight: 400,
                overflowY: 'scroll',
                marginTop: 10
            }}
            header={<span>DANH SÁCH ĐƠN ({data.length}) <a
                onClick={() => copyListOrder(data)}>COPY DANH SÁCH ĐƠN</a></span>}
            bordered={true}
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item key={item + index}>
                    <CheckCircleOutlined style={{color: colors_status_order_scanned[item.status]}}/> {item.order_id}
                    <Tag
                        color={colors_status_order_scanned[item.status]}
                        style={{float: 'right'}}>{item.message}</Tag>
                </List.Item>
            )}
        />
    }

    return <div>
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Đóng hàng</Breadcrumb.Item>
        </Breadcrumb>


        <Modal title="Danh sách đơn" visible={isModalListOrderOpen} onOk={handleOkOnModalListOrder}
               width={700}
               onCancel={handleCancelOnModalListOrder}>
            <List
                style={{
                    maxHeight: 400,
                    overflowY: 'scroll',
                    marginTop: 10
                }}
                bordered={true}
                dataSource={list_order_id}
                renderItem={(item, index) => (
                    <List.Item key={item + index}>
                        <CheckCircleOutlined
                            style={{color: '#52c41a'}}/> {item.order_id} | {item.status_source_readable} <Tag
                        onClick={() => {
                            onSearchOrder(item.order_id);
                            setIsModalSearchVisible(true);
                        }}
                        color={'#87d068'}
                        style={{float: 'right', cursor: 'pointer'}}><SearchOutlined/></Tag>
                        {
                            item.distance_to_now === 0 ? <Tag color={'red'} style={{float: 'right'}}>Hôm nay</Tag> :
                                <Tag color={'red'} style={{float: 'right'}}>{item.distance_to_now} ngày trước
                                    ({item.first_created_at})</Tag>
                        }

                    </List.Item>
                )}
            />
        </Modal>

        <Modal title="Tìm kiếm đơn hàng" visible={isModalSearchVisible} onOk={handleOkOnModalSearch}
               onCancel={handleCancelOnModalSearch} okText={'Tìm kiếm'} cancelText={'Hủy'}
               confirmLoading={searchingOrder}
        >
            <Input
                size="large"
                placeholder="Nhập mã vận đơn"
                prefix={<SearchOutlined/>}
                ref={inputSearchRef}
                onPressEnter={() => onSearchOrder(inputSearchRef.current.input.value)}
            />
            <Divider/>
            {Object.keys(dataOrder).length === 0 ?
                <div style={{textAlign: 'center'}}>Không có data</div> :
                <>
                    <Row>
                        <Col span={8}>
                            <b>Ngày tạo</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.created_at_format}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <b>Mã vận đơn</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.order_id}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Nguồn đơn</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.order_sources_name}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Đơn vị vận chuyển</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.partner_name}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái POS</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.status_readable}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái in</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.printed ? <Tag color={'green'}>{dataOrder.printed_by} đã in
                                    lúc {dataOrder.printed_at_format}</Tag> :
                                <Tag color={'red'}>Chưa in</Tag>}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái đóng gói</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.packed ?
                                <Tag color={'green'}>{dataOrder.packed_by} đã đóng gói
                                    lúc {dataOrder.packed_at_format}</Tag> :
                                <Tag color={'red'}>Chưa đóng gói</Tag>}
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <b>Trạng thái giao hàng</b>
                        </Col>
                        <Col span={16}>
                            {dataOrder.shipped ?
                                <Tag color={'green'}>Đã giao hàng lúc {dataOrder.shipped_at_format}</Tag> :
                                <Tag color={'red'}>Chưa giao hàng</Tag>}
                        </Col>
                    </Row>

                </>

            }

        </Modal>

        <div
            className="site-layout-background"
            style={{
                padding: 24, minHeight: 360,
            }}
        >

            <Modal title={'Quét đơn đã in'}
                   width={800}
                   visible={isModalScanCodeVisible}
                   onOk={handleOkOnModalScanCode}
                   onCancel={handleCancelOnModalScanCode}
                   okText={'XỬ LÝ ĐƠN'}
                   footer={[
                       <Button onClick={handleCancelOnModalScanCode}>Đóng</Button>,
                   ]}
            >
                <Spin spinning={onAddPrintedOrder} delay={500}>
                    <Space direction={'vertical'} style={{width: '100%'}}>
                        <Row align={'center'}>
                            <Input
                                size="large"
                                placeholder="quét mã vận đơn"
                                prefix={<BarcodeOutlined/>}
                                ref={inputScanOrderRef}
                                value={currentIdOrder}
                                onPressEnter={onScanOrder}
                                onChange={(e) => setCurrentIdOrder(e.target.value)}
                            />
                        </Row>
                        <Tabs>
                            <Tabs.TabPane tab={<>TẤT CẢ <Tag>{getListOrderByStatus(['all']).length}</Tag></>}
                                          key="item-1">
                                {renderListOrderScanned(['all'])}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={<>THÀNH CÔNG <Tag
                                    color="#87d068">{getListOrderByStatus(['new', 'exists']).length}</Tag></>}
                                key="item-2">
                                {renderListOrderScanned(['new', 'exists'])}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={<>THẤT BẠI <Tag color="#f50">{getListOrderByStatus(['failed']).length}</Tag></>}
                                key="item-3">
                                {renderListOrderScanned(['failed'])}
                            </Tabs.TabPane>
                        </Tabs>

                    </Space>
                </Spin>
            </Modal>

            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title="ĐƠN MỚI" bordered={true} headStyle={{
                            background: 'rgb(82, 196, 26)', color: 'white'
                        }}>
                            {summaryOrder.total_new_order}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ĐÃ ĐÓNG HÔM NAY" bordered={true} headStyle={{
                            background: 'rgb(26,60,196)', color: 'white'
                        }}>
                            {summaryOrder.total_packed_today}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ĐÃ GỬI HÔM NAY" bordered={true} headStyle={{
                            background: 'rgb(26,196,165)', color: 'white'
                        }}>
                            {summaryOrder.total_shipped_today}
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title="CHƯA IN" bordered={true} headStyle={{
                            background: 'rgb(231,110,93)', color: 'white'
                        }}>
                            {summaryOrder.total_not_printed}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ĐÃ IN CHƯA ĐÓNG HÀNG" bordered={true} headStyle={{
                            background: 'rgb(245,220,72)', color: 'white'
                        }}>
                            {summaryOrder.total_printed_not_packed}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ĐÃ ĐÓNG CHƯA GỬI" bordered={true} headStyle={{
                            background: 'rgb(183,28,164)', color: 'white'
                        }}>
                            {summaryOrder.total_packed_not_shipped}
                        </Card>
                    </Col>
                </Row>
            </div>
            <Divider/>
            <Row style={{}}>
                <Col>
                    <Space>
                        <Button type={"primary"} icon={<PrinterOutlined/>} onClick={showModalScanCode}
                                style={{background: 'rgb(255,147,24)', color: 'white', border: "none"}}>Nhập đơn
                            in</Button>
                        <Button type={"primary"} icon={<PlusOutlined/>} onClick={onNewSessionPackedOrder}
                                style={{background: 'rgb(82, 196, 26)', color: 'white', border: "none"}}>Tạo phiên
                            mới</Button>
                        <Button type={"primary"} icon={<SearchOutlined/>} onClick={showModalSearch}
                                style={{color: 'white', border: "none"}}>Tìm đơn</Button>
                    </Space>
                </Col>
            </Row>
            <br/>
            <div className="card-container">
                <Table
                    rowKey={(record) => record.order_id}
                    pagination={pagination}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                />
            </div>

        </div>
    </div>
}

export default PackedOrderPage;