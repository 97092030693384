import React, {useEffect, useState} from 'react';
import {Column, Pie} from '@ant-design/plots';
import FormFilterChart from "./FormFilterChart";
import reportService from "../../services/report.service";
import {Col, Row} from "antd";
import moment from "moment";

const ChartOrderReturn = () => {
    const [data, setData] = useState({
        data_chart: [],
        data_pie: []
    });

    const fetchData = (data_filter, group = 'days') => {
        reportService.getDataChartOrderReturn({match: JSON.stringify(data_filter), group})
            .then((response) => response.data)
            .then((res) => {
                setData(res.data);
            });
    }

    useEffect(() => {

        fetchData([
            {
                "yearMonthDayUTC": {
                    "$gte": moment().startOf('month').format('YYYY-MM-DD'),
                    "$lte": moment().format('YYYY-MM-DD')
                }
            }
        ]);
    }, [])
    const config_column = {
        data: data.data_chart,
        xField: '_id',
        yField: 'value',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };
    const config_pie = {
        appendPadding: 10,
        data: data.data_pie,
        angleField: 'value',
        colorField: '_id',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: 'TỈ LỆ HOÀN',
            },
        },
    };
    return <div className={'panel'}>
        <div className={'panel-header'}>
            <div className={'panel-title'}>Hàng hoàn</div>
            <div className={'panel-extra'}>
                <FormFilterChart onSubmit={fetchData}/>
            </div>
        </div>
        <div className={'panel-body'}>
            <Row>
                <Col span={12}>
                    <Column {...config_column} />
                </Col>
                <Col span={12}>
                    <Pie {...config_pie} />
                </Col>
            </Row>
        </div>
    </div>;
};


export default ChartOrderReturn;