import {Route, Routes} from "react-router-dom";
import MasterLayout from "./components/layout/MasterLayout";
import HomePage from "./pages/HomePage";
import HistoryPage from "./pages/HistoryPage";
import CardPage from "./pages/CardPage";
import CalenderPage from "./pages/Calender/CalenderPage";
import {Col, ConfigProvider, Row, Spin} from "antd";
import vi_VN from 'antd/lib/locale-provider/vi_VN';
import 'moment/locale/vi';
import ReturnOrderPage from "./pages/ReturnOrder/ReturnOrderPage";
import userService from './services/user.service'
import settingService from './services/setting.service'
import {useEffect, useState} from "react";
import LoginPage from "./pages/Login/LoginPage";
import {useRecoilState} from "recoil";
import {userProfileAtom} from "./recoil/atom/user.atom";
import TraceOrderPage from "./pages/TraceOrder/TraceOrderPage";
import {pageInfoAtom} from "./recoil/atom/page.atom";
import PermissionDeny from "./components/shared/PermissionDeny";
import PurchaseHistoryPage from "./pages/Purchase/PurchaseHistoryPage";
import {settingAtom} from "./recoil/atom/setting.atom";
import PaymentHistoryPage from "./pages/Purchase/PaymentHistoryPage";
import PackedOrderPage from "./pages/PackedOrder/PackedOrderPage";
import PackedOrderDetailPage from "./pages/PackedOrder/PackedOrderDetailPage";
import OrdersPage from "./pages/PackedOrder/OrdersPage";

const ProtectedRoute = ({user, children}) => {
    if (user.user_type !== 'admin') return <PermissionDeny/>
    return children;
}

const App = () => {
    const [userProfileState, setUserProfileState] = useRecoilState(userProfileAtom);
    const [settingState, setSettingState] = useRecoilState(settingAtom);
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isLogged, setIsLogged] = useState(false);
    const access_token = userService.getAccessToken();
    const refresh_token = userService.getRefreshToken();

    useEffect(() => {
        settingService.getSettings()
            .then(res => res.data)
            .then(setting => setSettingState(setting))
    }, [])

    useEffect(() => {
        if (access_token) {
            userService.getProfile(access_token)
                .then(res => {
                    setUserProfileState(res.data.user);
                    setIsLogged(true);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        userService.refreshToken(refresh_token)
                            .then(res => {
                                const {user} = res.data;
                                setUserProfileState(user);
                                userService.setAccessToken(user.access_token);
                                userService.setRefreshToken(user.refresh_token);
                                setIsLogged(true);
                            })
                            .catch(err => {
                                setIsLogged(false);
                            })
                    } else {
                        setIsLogged(false);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setIsAuthenticating(false);
                    }, 700)
                })
        } else {
            setIsAuthenticating(false);
            // setIsLogged(false);
        }

    }, []);


    if (isAuthenticating) {
        return <Row justify={'center'} align={'middle'} style={{height: '100vh'}}><Col><Spin size="large"/></Col></Row>
    }
    if (!isLogged) {
        return <LoginPage/>
    }
    return (<ConfigProvider locale={vi_VN}>
        <MasterLayout>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/dashboard" element={<HomePage/>}/>
                <Route path="/packed-order" element={<PackedOrderPage/>}/>
                <Route path="/orders" element={<OrdersPage/>}/>
                <Route path="/packed-order/:session_id" element={<PackedOrderDetailPage/>}/>
                {/*<Route path="/purchase" element={*/}
                {/*    <ProtectedRoute user={userProfileState}>*/}
                {/*        <PurchasePage/>*/}
                {/*    </ProtectedRoute>*/}
                {/*}/>*/}
                <Route path="/purchase-history" element={<ProtectedRoute user={userProfileState}>
                    <PurchaseHistoryPage/>
                </ProtectedRoute>}/>
                <Route path="/payment-history" element={<ProtectedRoute user={userProfileState}>
                    <PaymentHistoryPage/>
                </ProtectedRoute>}/>
                <Route path="/card" element={<CardPage/>}/>
                <Route path="/history" element={<HistoryPage/>}/>
                <Route path="/calender" element={<CalenderPage/>}/>
                <Route path="/document" element={<HistoryPage/>}/>
                <Route path="/trace-order" element={<TraceOrderPage/>}/>
                <Route path="/return-order" element={<ReturnOrderPage/>}/>
            </Routes>
        </MasterLayout>
    </ConfigProvider>);
};

export default App;