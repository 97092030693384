import {atom} from "recoil";

const itemSelectedAtom = atom({
    key: 'itemSelectedAtom', // unique ID (with respect to other atoms/selectors)
    default: {}
})

const boardSelectedAtom = atom({
    key: 'boardSelectedAtom', // unique ID (with respect to other atoms/selectors)
    default: ''
})


const listBoardAtom = atom({
    key: 'listBoardAtom', // unique ID (with respect to other atoms/selectors)
    default: {}
})

export {
    itemSelectedAtom,
    listBoardAtom,
    boardSelectedAtom
}