import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const queryPackedOrders = (filters, options) => {
    console.log('options', options)
    return axios.get(`${API_ENDPOINT}/v1/packed`, {
        params: {...options, ...filters}
    })
}
const queryOrders = (filters, options) => {
    console.log('options', options)
    return axios.get(`${API_ENDPOINT}/v1/packed/orders`, {
        params: {...options, ...filters}
    })
}

const createPackedOrder = () => {
    return axios.post(`${API_ENDPOINT}/v1/packed`)
}

const searchOrder = (order_id) => {
    return axios.get(`${API_ENDPOINT}/v1/packed/search-order/${order_id}`)
}

const getDetailPackedOrder = (session_id) => {
    return axios.get(`${API_ENDPOINT}/v1/packed/${session_id}`)
}

const addOrderToSession = (session_id, order_id) => {
    return axios.post(`${API_ENDPOINT}/v1/packed/${session_id}`, {
        order_id
    })

}

const addBulkPackedOrder = (session_id, list_order_id, status, updateProgress) => {
    return axios.post(`${API_ENDPOINT}/v1/packed/${session_id}/bulk`, {
        list_order_id,
        status
    }, {
        onDownloadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateProgress(percentCompleted)
        }
    })
}

const getSummaryOrder = () => {
    return axios.get(`${API_ENDPOINT}/v1/packed/summary`)
}

const checkOrder = (order_id) => {
    return axios.get(`${API_ENDPOINT}/v1/packed/check-order/${order_id}`)
}

const printedOrder = (order_id) => {
    return axios.post(`${API_ENDPOINT}/v1/packed/printed`, {
        order_id
    })
}

export default {
    queryPackedOrders,
    createPackedOrder,
    searchOrder,
    getDetailPackedOrder,
    addBulkPackedOrder,
    getSummaryOrder,
    checkOrder,
    addOrderToSession,
    printedOrder,
    queryOrders
}