import {Draggable, Droppable} from "react-beautiful-dnd";
import {Affix, Button, List, Skeleton, Tag} from "antd";
import Task from "./Task";
import {useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const ContainerHeight = 600;

function ItemTask(item, index) {
    return <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
            <List.Item key={item.id}
                       ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div>{item.name}</div>
            </List.Item>
        )}
    </Draggable>;
}

function ListTask(props) {
    const [loading, setLoading] = useState(false);

    return <Droppable droppableId={props.droppableId}>
        {(provided) => (
            <div className={'list-task'}
                 {...provided.droppableProps}
                 ref={provided.innerRef}>
                <Affix>
                    <div style={{background: props.list.color, padding: '10px 10px'}}>
                        {props.list.name} <Tag color="lime" style={{marginLeft: 5}}>{props.list.tasks.totalDocs}</Tag>
                        <Button size={'small'} style={{float: 'right'}} onClick={() => {
                            props.addNewItem(props.list.key, props.list.id)
                        }}>+</Button>
                    </div>
                </Affix>
                <div
                    id={`scrollableDiv_${props.droppableId}`}
                    style={{
                        height: 'calc(100vh - 180px)',
                        overflow: 'auto',
                    }}
                >

                    <InfiniteScroll
                        dataLength={props.list.tasks.docs.length}
                        next={() => {
                            props.loadMoreData(props.droppableId, props.list.id, 1)
                        }}
                        hasMore={props.list.tasks.hasNextPage}
                        loader={
                            <List.Item>
                                <Skeleton
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            </List.Item>
                        }
                        scrollableTarget={`scrollableDiv_${props.droppableId}`}
                    >
                        <List
                            bordered
                            dataSource={props.list.tasks.docs}
                            renderItem={(item, index) => (
                                <Task item={item} index={index} showModalDetailTask={props.showModalDetailTask}
                                      onDeleteItem={props.onDeleteItem}/>
                            )}
                        />
                        {provided.placeholder}

                    </InfiniteScroll>
                </div>
            </div>
        )}
    </Droppable>
}

export default ListTask;