import {Breadcrumb, Table, Typography} from "antd";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import {useEffect, useState} from "react";
import purchaseService from "../../services/purchase.service";
import {formatCurrency} from "../../utils/common";
import moment from "moment";
import SummaryDebt from "./SummaryDebt";
import FormFilterPaymentHistory from "./FormFilterPaymentHistory";

const {Text} = Typography;

const pageSize = 20;
const columns = [
    {
        title: 'ID', dataIndex: '_id', key: '_id', render: (text) => text.substring(text.length - 4),
    },
    {
        title: 'NHÀ CUNG CẤP',
        dataIndex: 'purchaseitem',
        key: 'purchaseitem',
        render: (purchaseitem) => purchaseitem?.supplier,
    },
    {
        title: 'LÔ HÀNG',
        dataIndex: 'purchaseitem',
        key: 'purchaseitem_name',
        render: (purchaseitem) => purchaseitem?.name,
    },
    {
        title: 'SẢN PHẨM',
        dataIndex: 'purchaseitem',
        key: 'product_name',
        render: (purchaseitem) => purchaseitem?.product,
    },
    {
        title: 'NGÀY THANH TOÁN', dataIndex: 'created_at', key: 'created_at',
        render: created_at => moment(created_at).format('HH:mm:ss DD/MM/YYYY')
    },
    {
        title: 'GIÁ TRỊ',
        dataIndex: 'amount',
        key: 'cost',
        render: (amount) => {
            return formatCurrency(amount);
        }
    }
];


const PaymentHistoryPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(false);

    const queryListPurchasePayment = (params) => {
        setLoading(true);

        let {filter, pagination} = params;
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current,
            limit: pagination.pageSize,
            filter: JSON.stringify(filter),
            cast: JSON.stringify({})
        };
        purchaseService.queryPaymentHistories(options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.docs);
                setPagination({
                    ...pagination,
                    total: res.metadata.total_docs
                })
            })
    };

    const onFilter = (data_filter) => {
        console.log('onFilter payment', data_filter)
        queryListPurchasePayment({
            filter: data_filter, pagination: {
                current: 1, pageSize: pageSize,
            }
        });
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        queryListPurchasePayment({
            filter, pagination: newPagination
        })
    };

    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'LỊCH SỬ THANH TOÁN'});
        queryListPurchasePayment({
            filter, pagination
        })
    }, [])
    return (
        <div>

            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>History</Breadcrumb.Item>
            </Breadcrumb>
            <FormFilterPaymentHistory onSubmit={onFilter}/>
            <br/>
            <SummaryDebt filter={filter}/>
            <br/>
            <Table
                rowKey={(record) => record._id}
                pagination={pagination}
                loading={loading}
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
            />
        </div>
    )
};

export default PaymentHistoryPage;