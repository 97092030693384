import {Breadcrumb, Col, Row} from "antd";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../recoil/atom/page.atom";
import {useEffect} from "react";
import ChartOrderReturn from "./Report/ChartOrderReturn";


const HomePage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'DASHBOARD'});
    }, [])
    return (
        <div>
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Card</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={16}>
                <Col span={24}>
                    <ChartOrderReturn/>
                </Col>
            </Row>
        </div>
    )
};

export default HomePage;