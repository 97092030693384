import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const getListBoard = (keyword = '') => {
    console.log('getListBoard', keyword)
    return axios.get(`${API_ENDPOINT}/v1/trace-order/board`, {
        params: {
            keyword
        }
    })
}

const getListItemByBoard = (board, page) => {
    return axios.get(`${API_ENDPOINT}/v1/trace-order/board/${board}/items`, {
        params: {page}
    })
}

const getDetailItem = (id) => {
    return axios.get(`${API_ENDPOINT}/v1/trace-order/item/${id}`)
}

const deleteItem = (id) => {
    return axios.delete(`${API_ENDPOINT}/v1/trace-order/item/${id}`)
}

const addItem = (data) => {
    return axios.post(`${API_ENDPOINT}/v1/trace-order/item`, data)
}

const updateItem = (id, data_update) => {
    return axios.put(`${API_ENDPOINT}/v1/trace-order/item/${id}`, data_update)
}

const addCommentItem = (id, data_comment) => {
    return axios.post(`${API_ENDPOINT}/v1/trace-order/item/${id}/comment`, data_comment)
}

export default {
    getListBoard,
    getListItemByBoard,
    addItem,
    updateItem,
    getDetailItem,
    addCommentItem,
    deleteItem
}