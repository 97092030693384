import {Button, Result} from 'antd';
import React from 'react';

const PermissionDeny = () => (
    <Result
        status="403"
        title="403"
        subTitle="không vào đây được đâu hehe, lêu lêu :P"
        extra={<Button type="primary">Back Home</Button>}
    />
);

export default PermissionDeny;