import axios from "../utils/api";
import {API_ENDPOINT} from "../config/config";

const getDataChartOrderReturn = async (params) => {
    return axios.get(`${API_ENDPOINT}/v1/report/data-chart/order-return`, {
        params: {
            ...params
        }
    })
}

export default {
    getDataChartOrderReturn
}