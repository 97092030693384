import axios from "axios";
import {API_ENDPOINT} from "../config/config";
import axiosWithConfig from '../utils/api'

const axiosUserService = axios.create();

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);
const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN_KEY);
const setAccessToken = token => window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
const setRefreshToken = token => window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
const logout = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.location.href = '/login';
};

const login = (username, password) => {
    return axiosUserService.post(`${API_ENDPOINT}/v1/user/login`, {username, password})
}

const getProfile = (access_token) => {
    return axiosUserService.get(`${API_ENDPOINT}/v1/user/profile`, {
        headers: {
            Authorization: access_token
        }
    })
}

const refreshToken = (refresh_token) => {
    return axiosUserService.post(`${API_ENDPOINT}/v1/user/refresh-token`, {refresh_token})
}

const listStaff = () => {
    return axiosWithConfig.get(`${API_ENDPOINT}/v1/user/staffs`,)
}

export default {
    getAccessToken,
    getRefreshToken,
    setRefreshToken,
    setAccessToken,
    logout,
    login,
    getProfile,
    refreshToken,
    listStaff
}