import {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Input, Result, Row, Select, Steps,} from "antd";
import {useNavigate} from 'react-router-dom';
import {SOCKET_ENDPOINT} from "../config/config";
import socketIOClient from "socket.io-client";
import {SyncOutlined} from "@ant-design/icons";
import * as apiCaller from "../services/card.service";
import {openNotificationWithIcon} from "../utils/common";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../recoil/atom/page.atom";


const {Step} = Steps;
const {Option} = Select;


const CardResult = (title, status, apiCalled, setApiCalled, navigate) => {
    status = parseInt(status);
    let loadingIcon = {};
    switch (status) {
        case 1:
            loadingIcon = {
                status: 'success'
            }
            break;
        case -1:
            loadingIcon = {
                status: 'warning',
                icon: <SyncOutlined spin/>
            };
            break;
        case 99:
            loadingIcon = {
                status: 'warning',
                icon: <SyncOutlined spin/>
            };
            break;
        default:
            loadingIcon = {
                status: 500
            }
            break;
    }

    return <Result
        {...loadingIcon}
        title={title}
        extra={[
            <Button type="primary" key="console" onClick={() => setApiCalled(false)}>
                Kiểm tra lại
            </Button>,
            <Button key="buy" onClick={() => navigate('/history')}>Lịch sử nạp</Button>,
        ]}
    />
}

function getForm(onFinishForm, onFinishFailedForm) {
    return <Form
        name="form_card"
        labelCol={{
            span: 8,
        }}
        wrapperCol={{
            span: 16,
        }}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailedForm}
        autoComplete="off"
    >
        <Form.Item
            label={'Nhà mạng'}
            name={'telco'}
            rules={[{required: true}]}
        >
            <Select
                placeholder={'Chọn nhà mạng'}
            >
                <Option value={'viettel'}>Viettel</Option>
                <Option value={'mobifone'}>Mobifone</Option>
                <Option value={'vinaphone'}>Vinaphone</Option>
            </Select>
        </Form.Item>
        <Form.Item
            label={'Mệnh giá'}
            name={'card_amount'}
            rules={[{required: true}]}
        >
            <Select
                placeholder={'Chọn mệnh giá thẻ cào'}
            >
                <Option value={10000}>10.000</Option>
                <Option value={20000}>20.000</Option>
                <Option value={30000}>30.000</Option>
                <Option value={50000}>50.000</Option>
                <Option value={100000}>100.000</Option>
                <Option value={200000}>200.000</Option>
                <Option value={500000}>500.000</Option>
            </Select>
        </Form.Item>
        <Form.Item
            label={'Mã thẻ cào'}
            name={'card_code'}
            rules={[{required: true, pattern: new RegExp(/^[0-9]+$/)}]}
        >
            <Input
                placeholder={'Nhập mã thẻ cào'}
            >
            </Input>
        </Form.Item>
        <Form.Item
            label={'Serial'}
            name={'card_serial'}
            rules={[{required: true, pattern: new RegExp(/^[0-9]+$/)}]}
        >
            <Input
                placeholder={'Nhập serial'}
            >
            </Input>
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button type="primary" htmlType="submit">
                GẠCH THẺ
            </Button>
        </Form.Item>
    </Form>;
}

const CardPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'NẠP THẺ'});
    }, [])
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [requestID, setRequestID] = useState(0);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [msgResult, setMsgResult] = useState('Thẻ cào đang được xử lý, vui lòng chờ trong giây lát');
    const [statusResult, setStatusResult] = useState(1);

    const onChangeStep = (value) => {
        console.log('onChange:', current);
        setCurrent(value);
    };

    const onFinishForm = (values) => {
        console.log('Success:', values);
        setIsSubmitSuccess(true);
        setMsgResult('Thẻ cào đang được xử lý, vui lòng chờ trong giây lát');

        apiCaller.submitCard(values)
            .then(({data}) => {
                if (data.code === 200) {
                    console.log('ok', data.data.req_id)
                    setApiCalled(true);
                    openNotificationWithIcon('success', data.message)
                    setRequestID(data.data.req_id);
                    setStatusResult(-1);
                    localStorage.setItem('req_id', data.data.req_id);
                } else {
                    openNotificationWithIcon('error', data.message, 'Lỗi rồiiiiii')
                }
            })
            .catch(error => {
                openNotificationWithIcon('error', error.message, 'Lỗi rồiiiiii')
            });
    };

    const onFinishFailedForm = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const socketRef = useRef();
    useEffect(() => {
        socketRef.current = socketIOClient.connect(SOCKET_ENDPOINT);
        socketRef.current.on('updateStatus', data => {
            console.log('updateStatus', data)
            const req_id = parseInt(localStorage.getItem('req_id'));
            console.log('req_id', req_id)
            if (parseInt(data.req_id) === req_id) {
                setMsgResult(data.message);
                setStatusResult(data.status)
            }
        });
        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    return (
        <div>
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Card</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{
                    padding: 24,
                    minHeight: 360,
                }}
            >
                <Row style={{
                    marginTop: 10
                }}>
                    <Col span={12} offset={6}>
                        {apiCalled ? CardResult(msgResult, statusResult, apiCalled, setApiCalled, navigate) : getForm(onFinishForm, onFinishFailedForm)}
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default CardPage;