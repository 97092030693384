import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const submitCard = (data) => {
    console.log('submit', data)
    return axios.post(`${API_ENDPOINT}/v1/card/submit-card`, data)
}

export {
    submitCard
}