import {atom} from "recoil";

const pageInfoAtom = atom({
    key: 'pageInfoAtom', // unique ID (with respect to other atoms/selectors)
    default: {
        title: '',
        searchbar: {
            x: 0,
            y: 0
        }
    }
})

const searchBarAtom = atom({
    key: 'searchBarAtom', // unique ID (with respect to other atoms/selectors)
    default: {
        x: 0,
        y: 15,
        width: 0
    }
})

export {
    pageInfoAtom,
    searchBarAtom
}