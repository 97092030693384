import React, {useEffect, useState} from 'react';
import {LockOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, Input, Select} from 'antd';
import {useRecoilValue} from "recoil";
import {settingAtom} from "../../recoil/atom/setting.atom";
import dayjs from "dayjs";

const {RangePicker} = DatePicker;

const FormFilterPurchaseBill = (props) => {
    const settingState = useRecoilValue(settingAtom);

    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});

    useEffect(() => {
        console.log('settingState', settingState)
        forceUpdate({});
    }, []);
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        let data_filter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value) {
                value = key === 'yearMonthDayUTC' ? {
                    "$gte": dayjs(value[0]).format('YYYY-MM-DD'),
                    "$lte": dayjs(value[1]).format('YYYY-MM-DD')
                } : value;
                data_filter.push({[key]: value})
            }
        }
        props.onSubmit(data_filter);
    };

    const onResetFilter = () => {
        form.resetFields();
        props.onSubmit([]);
    }

    return (
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
            <Form.Item
                name="purchase_item.supplier"
            >
                <Select
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    style={{
                        width: 180,
                    }}
                    placeholder="Nhà cung cấp"
                    onChange={(value) => {

                    }}
                    options={settingState.suppliers.map(item => {
                        return {
                            value: item,
                            label: item
                        }
                    })}
                />
            </Form.Item>

            <Form.Item
                name="purchase_item.product"
            >
                <Select
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    style={{
                        width: 180,
                    }}
                    placeholder="Sản phẩm"
                    onChange={(value) => {

                    }}
                    options={settingState.products.map(item => {
                        return {
                            value: item,
                            label: item
                        }
                    })}
                />
            </Form.Item>
            <Form.Item
                name="purchase_item.name"
            >
                <Input
                    style={{
                        width: 180,
                    }}
                    placeholder="Lô hàng"
                    onChange={(value) => {

                    }}
                    options={settingState.products.map(item => {
                        return {
                            value: item,
                            label: item
                        }
                    })}
                />
            </Form.Item>
            <Form.Item
                name="yearMonthDayUTC"
            >
                <RangePicker
                    style={{
                        width: 280,
                    }}
                    placeholder={['Bắt đầu', 'Kết thúc']}
                    format={'DD-MM-YYYY'}
                    onChange={(date, dateString) => {
                        console.log(dateString);
                    }}/>
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="danger"
                        htmlType="submit"
                        onClick={onResetFilter}
                    >
                        RESET
                    </Button>
                )}
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        LỌC
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};
export default FormFilterPurchaseBill;