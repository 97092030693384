import {Breadcrumb, Button, Calendar, Col, Row, Select, Space, Tag} from "antd";
import {CheckSquareOutlined, CloseSquareOutlined, ExportOutlined, PlusOutlined} from "@ant-design/icons";
import './style.css'
import React, {useEffect, useState} from "react";
import ModalAddNew from "./ModalAddNew";
import workScheduleService from '../../services/work-schedule.service'
import userService from "../../services/user.service";
import moment from "moment";
import ModalDetail from "./ModalDetail";
import ModalReport from "./ModalReport";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";

const {Option} = Select;

const CalenderPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'LỊCH LÀM VIỆC'});
    }, [])
    const [staffs, setStaffs] = useState([]);
    const [listWorkSchedule, setListWorkSchedule] = useState([]);
    const [listWorkScheduleOnModalDetail, setListWorkScheduleOnModalDetail] = useState([]);
    const [dateSelected, setDateSelected] = useState();
    const [isModalAddNewVisible, setIsModalAddNewVisible] = useState(false);
    const showModalAddNew = () => {
        setIsModalAddNewVisible(true,);
    };
    const handleOkOnModalAddNew = () => {
        setIsModalAddNewVisible(false);
    };
    const handleCancelOnModalAddNew = () => {
        setIsModalAddNewVisible(false);
    };

    const [isModalReportVisible, setIsModalReportVisible] = useState(false);
    const showModalReport = () => {
        setIsModalReportVisible(true,);
    };
    const handleOkOnModalReport = () => {
        setIsModalReportVisible(false);
    };
    const handleCancelOnModalReport = () => {
        setIsModalReportVisible(false);
    };

    const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
    const showModalDetail = (date) => {
        const stringValue = date.format("DD/MM/yyyy");
        const listData = listWorkSchedule.filter(({date_format}) => date_format === stringValue);
        setListWorkScheduleOnModalDetail(listData);
        setDateSelected(stringValue);
        setIsModalDetailVisible(true,);
    };
    const handleOkOnModalDetail = () => {
        setIsModalDetailVisible(false);
    };
    const handleCancelOnModalDetail = () => {
        setIsModalDetailVisible(false);
    };

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };


    const [filter, setFilter] = useState({});
    const getListWorkSchedule = (new_filter, force) => {
        if (filter.start_date !== new_filter.start_date || force) {
            setFilter(new_filter);
            workScheduleService.getListWorkSchedule(new_filter)
                .then(res => {
                    console.log('getListWorkSchedule', res)
                    setListWorkSchedule(res.data)
                })
                .catch(err => {
                    console.log('err getListWorkSchedule', err)
                })
        }
    }

    useEffect(() => {
        const start_date = moment().startOf('month').format("yyyy-MM-DD")
        const end_date = moment().endOf('month').format("yyyy-MM-DD");

        getListWorkSchedule({
            start_date,
            end_date
        });
    }, []);

    const onReloadData = () => {
        console.log('onReloadData')
        getListWorkSchedule(filter, true);
    }

    const dateCellRender = (value) => {
        const stringValue = value.format("DD/MM/yyyy");
        const listData = listWorkSchedule.filter(({date_format}) => date_format === stringValue)
        return (
            <div>
                {listData.map((item) => {
                    return <ul key={item._id} className="events">
                        <Tag style={{width: '100%', margin: 0}} color="volcano">{item.staff.full_name}</Tag>
                        {
                            item.shifts.map((shift, index) => {
                                return shift.status
                                    ? <li key={index}><CheckSquareOutlined
                                        style={{color: '#52c41a'}}/> {shift.shift_label}
                                    </li>
                                    : <li key={index}><CloseSquareOutlined
                                        style={{color: '#eb2f96'}}/> {shift.shift_label}
                                    </li>

                            })
                        }
                    </ul>
                })}
            </div>
        );
    };


    useEffect(() => {

        userService.listStaff()
            .then(res => {
                setStaffs(res.data.result)
            })
            .catch(err => {

            })
    }, []);
    return (
        <div>
            <ModalReport visible={isModalReportVisible}
                         onReloadData={onReloadData}
                         onOk={handleOkOnModalReport}
                         onCancel={handleCancelOnModalReport}
                         staffs={staffs}
            />
            <ModalAddNew visible={isModalAddNewVisible}
                         onReloadData={onReloadData}
                         onOk={handleOkOnModalAddNew}
                         onCancel={handleCancelOnModalAddNew}
                         staffs={staffs}
            />
            <ModalDetail visible={isModalDetailVisible}
                         onOk={handleOkOnModalDetail}
                         onCancel={handleCancelOnModalDetail}
                         listData={listWorkScheduleOnModalDetail}
                         dateSelected={dateSelected}
            />
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Lịch làm việc</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{
                    padding: 24,
                    minHeight: 360,
                }}
            >
                <Calendar
                    headerRender={({value, type, onChange, onTypeChange}) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];
                        const current = value.clone();
                        const localeData = value.localeData();
                        const start_date = current.startOf('month').format("yyyy-MM-DD")
                        const end_date = current.endOf('month').format("yyyy-MM-DD");

                        getListWorkSchedule({
                            ...filter,
                            start_date,
                            end_date
                        });
                        const months = [];

                        for (let i = 0; i < 12; i++) {
                            current.month(i);
                            months.push(localeData.monthsShort(current));
                        }

                        for (let i = start; i < end; i++) {
                            monthOptions.push(
                                <Select.Option key={i} value={i} className="month-item">
                                    {months[i]}
                                </Select.Option>,
                            );
                        }

                        const year = value.year();
                        const month = value.month();
                        const options = [];

                        for (let i = year - 10; i < year + 10; i += 1) {
                            options.push(
                                <Select.Option key={i} value={i} className="year-item">
                                    {i}
                                </Select.Option>,
                            );
                        }

                        console.log('month', month + 1)

                        return (
                            <div
                                style={{
                                    padding: 8,
                                }}
                            >
                                <Row gutter={8} align={'middle'}>
                                    <Col span={6}>
                                        <Space>
                                            <Button type={"danger"} icon={<PlusOutlined/>}
                                                    onClick={showModalAddNew}>THÊM LICH</Button>
                                            <Button style={{background: '#faad14', color: 'white', border: "none"}}
                                                    onClick={showModalReport}

                                                    icon={<ExportOutlined/>}
                                            >
                                                XUẤT LỊCH
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col span={18}>
                                        <Row justify={'end'}>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        size="small"
                                                        dropdownMatchSelectWidth={false}
                                                        className="my-year-select"
                                                        placeholder={'Chọn nhân viên'}
                                                        onChange={(staff) => {
                                                            console.log('staff', staff)
                                                            getListWorkSchedule({
                                                                ...filter,
                                                                staff
                                                            }, true);
                                                        }}
                                                    >
                                                        <Option value={0}>Tất cả</Option>
                                                        {staffs.map(staff => <Option value={`${staff.id}`}
                                                                                     key={staff.id}>{staff.full_name}</Option>)}
                                                    </Select>

                                                    <Select
                                                        size="small"
                                                        dropdownMatchSelectWidth={false}
                                                        className="my-year-select"
                                                        value={year}
                                                        onChange={(newYear) => {
                                                            const now = value.clone().year(newYear);
                                                            onChange(now);
                                                        }}
                                                    >
                                                        {options}
                                                    </Select>
                                                    <Select
                                                        size="small"
                                                        dropdownMatchSelectWidth={false}
                                                        value={month}
                                                        onChange={(newMonth) => {
                                                            const now = value.clone().month(newMonth);
                                                            onChange(now);
                                                        }}
                                                    >
                                                        {monthOptions}
                                                    </Select>
                                                </Space>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        );
                    }}
                    onSelect={showModalDetail}
                    onPanelChange={onPanelChange} dateCellRender={dateCellRender}/>
            </div>
        </div>
    )
};

export default CalenderPage;