import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, Select, Space} from 'antd';
import dayjs from "dayjs";

const {RangePicker} = DatePicker;

const FormFilterOrderReturn = (props) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});

    useEffect(() => {
        forceUpdate({});
    }, []);
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        let data_filter = {};
        for (let [key, value] of Object.entries(values)) {
            if (value) {
                // value = key === 'yearMonthDayUTC' ? {
                //     "$gte": dayjs(value[0]).format('YYYY-MM-DD'),
                //     "$lte": dayjs(value[1]).format('YYYY-MM-DD')
                // } : value;
                if (key === 'status' && value === 'all') {
                    continue;
                } else if (key === 'created_at' || key === 'updated_at') {
                    value = {
                        "$gte": dayjs(value[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
                        "$lte": dayjs(value[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z'
                    }
                    value = JSON.stringify(value);
                }
                data_filter[key] = value;
            }
        }
        console.log('data_filter', data_filter);
        props.onSubmit(data_filter);
    };

    const onResetFilter = () => {
        form.resetFields();
        props.onSubmit([]);
    }

    return (
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
            <Space wrap={true} size={5}>
                <Form.Item
                    name="order_id"
                >
                    <Input
                        style={{
                            width: 120,
                        }}
                        placeholder="Mã đơn"
                    />
                </Form.Item>
                <Form.Item
                    name="status"
                >
                    <Select
                        style={{
                            width: 120,
                        }}
                        placeholder="Trạng thái"
                        options={[
                            {label: 'Tất cả', value: 'all'},
                            {label: 'Chờ hoàn', value: 'pending'},
                            {label: 'Đã hoàn', value: 'returned'},
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="from_source"
                >
                    <Select
                        style={{
                            width: 120,
                        }}
                        placeholder="Nguồn"
                        options={[
                            {label: 'Scan', value: 'scan'},
                            {label: 'Import file', value: 'file'},
                            {label: 'Form', value: 'form'},
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="update_source"
                >
                    <Select
                        style={{
                            width: 150,
                        }}
                        placeholder="Phương thức hoàn"
                        options={[
                            {label: 'Scan', value: 'scan'},
                            {label: 'Import file', value: 'file'},
                            {label: 'Form', value: 'form'},
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    name="created_at"
                >
                    <RangePicker
                        style={{
                            width: 220,
                        }}
                        placeholder={['Ngày tạo', 'Kết thúc']}
                        format={'DD-MM-YYYY'}
                        onChange={(date, dateString) => {
                            console.log(dateString);
                        }}/>
                </Form.Item>
                <Form.Item
                    name="updated_at"
                >
                    <RangePicker
                        style={{
                            width: 220,
                        }}
                        placeholder={['Hoàn lúc', 'Kết thúc']}
                        format={'DD-MM-YYYY'}
                        onChange={(date, dateString) => {
                            console.log(dateString);
                        }}/>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="danger"
                            htmlType="submit"
                            onClick={onResetFilter}
                        >
                            RESET
                        </Button>
                    )}
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            LỌC
                        </Button>
                    )}
                </Form.Item>
            </Space>
        </Form>
    );
};
export default FormFilterOrderReturn;