import React, {useState} from 'react';
import {Button, List, message, Modal, notification, Popconfirm, Progress, Row, Tag, Upload} from 'antd';
import {CheckCircleOutlined, InboxOutlined} from '@ant-design/icons';
import {API_ENDPOINT} from "../../config/config";
import orderService from "../../services/order.service";
import {useRecoilValue} from "recoil";
import {userProfileAtom} from "../../recoil/atom/user.atom";

const {Dragger} = Upload;

const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'cyan',
    'returned': 'volcano',
    'exists': 'blue',
}

const name_status_order_scanned = {
    'none': 'chờ xử lý',
    'new': 'thêm mới thành công',
    'exists': 'đơn tồn tại',
    'returned': 'đơn đã hoàn hàng',
}


const ModalImportFile = (props) => {
    const userProfileState = useRecoilValue(userProfileAtom);
    let [listOrderId, setListOrderId] = useState([]);
    const [statusProgressUpdateBulk, setStatusProgressUpdateBulk] = useState('normal');
    const [isStartingUpdateBulk, setIsStartingUpdateBulk] = useState(false);
    const [percentProgressUpdateBulk, setPercentProgressUpdateBulk] = useState(0);

    const propsUpload = {
        name: 'file',
        multiple: true,
        action: `${API_ENDPOINT}/v1/order/import-file`,
        headers: {
            authorization: userProfileState.access_token,
        },
        onChange(info) {
            console.log('info', info)
            const {status, response} = info.file;

            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }

            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                listOrderId = [
                    ...listOrderId,
                    ...response.list_order_id
                ]
                setListOrderId(listOrderId)
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const updateBulk = async () => {
        try {
            setStatusProgressUpdateBulk('active');
            setIsStartingUpdateBulk(true);
            setPercentProgressUpdateBulk(0);
            let result = await orderService.addBulkOrderReturns(listOrderId, (percent) => {
                setPercentProgressUpdateBulk((percent));
            });
            console.log('updateBulk', result.data.result)
            setListOrderId(result.data.result);
            setStatusProgressUpdateBulk('success');
            props.onReloadData();
            notification['success']({
                message: 'Thông báo',
                description: 'Đã xử lý thành công'
            });
        } catch (e) {
            console.log('loiiiii', e);
            notification['error']({
                message: 'Thông báo',
                description: e.message
            });
            setStatusProgressUpdateBulk('exception');
        }
    }

    return <Modal title="Import file"
                  visible={props.visible}
                  onOk={props.onOk}
                  onCancel={props.onCancel}
                  footer={[
                      <Button onClick={props.onCancel}>Đóng</Button>,
                      <Popconfirm
                          title="Danh sách đơn hàng đã quét sẽ bị xoá"
                          onConfirm={() => {
                              setListOrderId([])
                          }}
                          onCancel={() => {
                          }}
                          okText="Xoá"
                          cancelText="Huỷ"
                      >
                          <Button type={'danger'}>Xoá data</Button>
                      </Popconfirm>,
                      <Button type={'primary'} onClick={updateBulk}>Xử Lý</Button>,
                  ]}
    >
        <Dragger {...propsUpload}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
                Hỗ trợ các loại file excel
            </p>
        </Dragger>
        <Row align={'center'}>
            {
                isStartingUpdateBulk ? <Progress
                    status={statusProgressUpdateBulk}
                    percent={percentProgressUpdateBulk}
                /> : ''
            }
        </Row>
        <List
            style={{
                maxHeight: 400,
                overflowY: 'scroll',
                marginTop: 10
            }}
            header={`DANH SÁCH ĐƠN (${listOrderId.length})`}
            bordered={true}
            dataSource={listOrderId}
            renderItem={(item) => (
                <List.Item key={item}>
                    <CheckCircleOutlined style={{color: '#52c41a'}}/> {item.order_id} <Tag
                    color={colors_status_order_scanned[item.status]}
                    style={{float: 'right'}}>{name_status_order_scanned[item.status]}</Tag>
                </List.Item>
            )}
        />

    </Modal>


}

export default ModalImportFile;