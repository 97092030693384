import React from 'react';
import {Button, Checkbox, DatePicker, Form, Input, Modal, notification, Select, Space} from 'antd';
import {BarcodeOutlined} from "@ant-design/icons";
import workScheduleService from "../../services/work-schedule.service";

const {Option} = Select;
const {TextArea} = Input;


const getShifts = (shifts) => {
    shifts = Array.isArray(shifts) ? shifts : [];
    console.log('shifts', shifts)
    return [
        {
            "shift_value": "morning_shift",
            "shift_label": "Ca sáng",
            "status": shifts.includes('morning_shift'),
        },
        {
            "shift_value": "afternoon_shift",
            "shift_label": "Ca chiều",
            "status": shifts.includes('afternoon_shift'),
        },
        {
            "shift_value": "night_shift",
            "shift_label": "Ca tối",
            "status": shifts.includes('night_shift'),
        }
    ];
}

const ModalAddNew = (props) => {
    const [form] = Form.useForm();
    const options = [
        {
            value: 'morning_shift',
            label: 'Ca sáng',
        },
        {
            value: 'afternoon_shift',
            label: 'Ca chiều',
        },
        {
            value: 'night_shift',
            label: 'Ca tối',
        },
    ];

    const onFinish = (values) => {
        const payload = {
            note: values.note,
            staff: values.staff,
            date: values.date.format("yyyy-MM-DD"),
            shifts: getShifts(values.shifts)
        }
        workScheduleService.addNewWorkSchedule(payload)
            .then(res => {
                notification['success']({
                    message: 'Thông báo',
                    description: 'Thêm lịch mới thành công'
                });
                form.resetFields();
                props.onReloadData();
                props.onCancel();
            })
            .catch(err => {
                notification['error']({
                    message: 'Thông báo',
                    description: err.response.data.message
                });
            });
    }

    return <Modal title="Sắp lịch làm"
                  visible={props.visible}
                  onOk={form.submit}
                  onCancel={props.onCancel}
                  footer={<></>}
    >
        <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="Nhân viên" name={'staff'} required>
                <Select placeholder="chọn nhân viên" prefix={<BarcodeOutlined/>}>
                    {props.staffs.map(staff => <Option value={`${staff.id}`} key={staff.id}>{staff.full_name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Thời gian" name={'date'} required>
                <DatePicker style={{width: '100%'}} onChange={(date) => {
                    console.log('date', date.format("yyyy-MM-DD"))
                }}/>
            </Form.Item>
            <Form.Item label="Ca làm" name="shifts" valuePropName="checked" required>
                <Checkbox.Group options={options}>Ca sáng</Checkbox.Group>
            </Form.Item>
            <Form.Item label="Ghi chú" name="note">
                <TextArea rows={2}/>
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button onClick={props.onCancel}>ĐÓNG</Button>
                    <Button type="primary" htmlType="submit">
                        THÊM MỚI
                    </Button>
                </Space>
            </Form.Item>
        </Form>

    </Modal>


}

export default ModalAddNew;