import {Table} from "antd";
import {formatCurrency} from "../../utils/common";

const TablePurchaseBill = (props) => {
    const dataSource = [];

    const columns = [
        {
            title: 'SẢN PHẨM',
            dataIndex: 'product',
            key: 'product',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'THUỘC TÍNH',
            dataIndex: 'variants',
            key: 'variants',
            render: variants => {
                let variants_str = '';
                for (const key in variants) {
                    variants_str += `${key}: ${variants[key]}, `
                }
                variants_str = variants_str.trim().replace(/,$/, '')
                return variants_str;
            }
        },
        {
            title: 'ĐƠN GIÁ',
            dataIndex: 'price',
            key: 'price',
            render: price => formatCurrency(price)
        },
        {
            title: 'TỔNG NHẬP',
            key: 'qty',
            dataIndex: 'qty',
        },
        {
            title: 'THÀNH TIỀN',
            key: 'cost',
            render: (_, record) => {
                return formatCurrency(record.qty * record.price);
            }
        },
    ];


    return <Table dataSource={props.data} columns={columns}/>;
}

export default TablePurchaseBill;