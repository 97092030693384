import React, {useState} from 'react';
import {Button, DatePicker, Descriptions, Form, Input, Modal, notification, Select, Space, Tag} from 'antd';
import {BarcodeOutlined, CommentOutlined} from "@ant-design/icons";
import workScheduleService from "../../services/work-schedule.service";

const {Option} = Select;
const {TextArea} = Input;


const ModalReport = (props) => {
    const [form] = Form.useForm();
    const [dataReport, setDataReport] = useState({
        "daysOfMonth": 0,
        "daysWorked": 0,
        "shift_off_allow": 0,
        "day_off_allow": 0,
        "shift_of_day": 0,
        "total_shift_of_month": 0,
        "total_shift_of_list": 0,
        "minimum_shift_of_month": 0,
        "total_shift_worked": 0,
        "distance_shift": 0,
        "shift_off_count": 0,
        "day_off_count": 0,
        "shift_up": 0,
        "days_up": 0,
        "label_color": "",
        "label": ""
    });
    const renderNote = (note) => {
        if (note) {
            return <><CommentOutlined style={{color: '#40a9ff'}}/> {note}</>
        } else {
            return <></>
        }
    }
    const onFinish = (values) => {
        const params = {
            staff: values.staff,
            start_date: values.date.startOf('month').format("yyyy-MM-DD"),
            end_date: values.date.endOf('month').format("yyyy-MM-DD"),
        }
        workScheduleService.getReportByStaff(params)
            .then(res => {
                setDataReport(res.data.data)
                notification['success']({
                    message: 'Thông báo',
                    description: 'Xuất report thành công'
                });
            })
            .catch(err => {
                notification['error']({
                    message: 'Thông báo',
                    description: err.response.data.message
                });
            });
    }

    return <Modal title={'Report'}
                  visible={props.visible}
                  onOk={form.submit}
                  onCancel={props.onCancel}
                  width={600}
                  footer={[
                      <Button onClick={props.onCancel}>Đóng</Button>,
                  ]}
    >
        <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="Nhân viên" name={'staff'} required>
                <Select placeholder="chọn nhân viên" prefix={<BarcodeOutlined/>}>
                    {props.staffs.map(staff => <Option value={`${staff.id}`} key={staff.id}>{staff.full_name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Thời gian" name={'date'} required>
                <DatePicker style={{width: '100%'}} picker="month" onChange={(date) => {
                    console.log('date', date.format("yyyy-MM-DD"))
                }}/>
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        XUẤT DỮ LIỆU
                    </Button>
                </Space>
            </Form.Item>
        </Form>
        <div>
            <Descriptions bordered column={2}>
                <Descriptions.Item label="NGÀY ĐÃ LÀM/THÁNG" span={2}>
                    <Tag color="gold">{dataReport.daysWorked}/{dataReport.daysOfMonth}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="SỐ CA/NGÀY" span={2}>
                    <Tag color="gold">{dataReport.shift_of_day}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="NGÀY LÀM TỐI THIỂU/THÁNG" span={2}>
                    <Space>
                        <Tag
                            color="gold">{dataReport.daysOfMonth - (dataReport.day_off_allow)}/{dataReport.daysOfMonth} NGÀY</Tag>
                        <Tag
                            color="gold">{dataReport.minimum_shift_of_month}/{dataReport.daysOfMonth * dataReport.shift_of_day} CA</Tag>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="CA ĐÃ LÀM" span={2}>
                    <Tag color="gold">{dataReport.total_shift_worked}/{dataReport.total_shift_of_month}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="ĐÃ NGHỈ" span={2}>
                    <Space>
                        <Tag color="gold">{dataReport.day_off_count}/{dataReport.day_off_allow} NGÀY</Tag>
                        <Tag color="gold">{dataReport.shift_off_count}/{dataReport.shift_off_allow} CA</Tag>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="TĂNG CA" span={2}>
                    <Space>
                        <Tag color="green">{dataReport.days_up} NGÀY</Tag>
                        <Tag color="green">{dataReport.shift_up} CA</Tag>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="KẾT QUẢ" span={2}>
                    <Tag color={dataReport.label_color}>{dataReport.label}</Tag>
                </Descriptions.Item>
            </Descriptions>
        </div>

    </Modal>


}

export default ModalReport;