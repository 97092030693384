import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {searchBarAtom} from "../../recoil/atom/page.atom";
import {debounce} from "../../utils/common";
import {useCallback} from "react";

const FormSearch = (props) => {
    const searchBarState = useRecoilValue(searchBarAtom);
    const debounceSearch = useCallback(debounce(props.onSearch), []);

    return <div style={{position: 'absolute', top: searchBarState.y, left: searchBarState.x}}>
        <Input size="large"
               style={{width: searchBarState.width}}
               placeholder={props.placeholder}
               bordered={false}
               prefix={<SearchOutlined/>}
               onChange={(e) => {
                   debounceSearch(e.target.value);
               }
               }/>

    </div>
}
export default FormSearch;