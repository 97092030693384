import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'antd';
import purchaseService from "../../services/purchase.service";
import {formatCurrency} from "../../utils/common";

const SummaryDebt = ({filter}) => {
    console.log('render SummaryDebt', filter)
    const [data, setData] = useState([]);
    useEffect(() => {
        getSummaryPayment();
    }, [filter]);

    const getSummaryPayment = () => {
        purchaseService.getSummaryPayment({filter: JSON.stringify(filter)})
            .then(res => res.data)
            .then(res => {
                setData(res);
            })
    }

    return (
        <div className="site-card-wrapper">
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="TỔNG TIỀN HÀNG" bordered={false}
                          headStyle={{textAlign: 'center', background: '#080089', color: 'white'}}>
                        <h1 style={{fontSize: 25, textAlign: 'center'}}>{formatCurrency(data.total_cost)}</h1>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="ĐÃ THANH TOÁN" bordered={false}
                          headStyle={{textAlign: 'center', background: 'rgb(39, 174, 96)', color: 'white'}}>
                        <h1 style={{fontSize: 25, textAlign: 'center'}}>{formatCurrency(data.total_paid)}</h1>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="CHƯA THANH TOÁN" bordered={false}
                          headStyle={{textAlign: 'center', background: 'rgb(250, 140, 22)', color: 'white'}}>
                        <h1 style={{fontSize: 25, textAlign: 'center'}}>{formatCurrency(data.total_debt)}</h1>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SummaryDebt;