import {Breadcrumb, Col, Row, Select, Spin} from "antd";
import './style.css'
import React, {useEffect, useState} from "react";
import {DragDropContext} from 'react-beautiful-dnd';
import ListTask from "./ListTask";
import {openNotificationWithIcon, removeItemByIndex} from "../../utils/common";
import ModalDetailTask from "./ModalDetailTask";
import traceOrderService from '../../services/trace-order.service'
import {useRecoilState} from "recoil";
import {boardSelectedAtom, itemSelectedAtom, listBoardAtom} from "../../recoil/atom/trace-order.atom";
import _ from 'underscore';
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import FormSearch from "../../components/shared/FormSearch";

const {Option} = Select;

const TraceOrderPage = (props) => {
    const [isLoadingBoard, setIsLoadingBoard] = useState(false);
    const [itemSelectedState, setItemSelectedState] = useRecoilState(itemSelectedAtom);
    const [boardSelectedState, setBoardSelectedState] = useRecoilState(boardSelectedAtom);
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'THEO DÕI ĐƠN HÀNG'});
    }, [])
    const [isLoadMore, setIsLoadMore] = useState({
        new: false,
        in_progress: false,
        success: false,
        recheck: false,
        failed: false
    });
    const [listBoardState, setListBoardState] = useRecoilState(listBoardAtom);

    const [isModalDetailTaskVisible, setIsModalDetailTaskVisible] = useState(false);
    const showModalDetailTask = (item) => {
        setItemSelectedState(item);
        setBoardSelectedState(getBoardByItem(item.id));
        setIsModalDetailTaskVisible(true,);
    };
    const handleOkOnModalDetailTask = () => {
        setIsModalDetailTaskVisible(false);
    };
    const handleCancelOnModalDetailTask = () => {
        setIsModalDetailTaskVisible(false);
        onUpdateItem();
    };

    const getBoardByItem = (item_id) => {
        let board_selected = _.filter(listBoardState, function (obj) {
            return _.where(obj.tasks.docs, {id: item_id}).length > 0;
        })
        return board_selected.length > 0 ? board_selected[0] : undefined;
    }

    const onUpdateItem = () => {
        let board_selected = _.filter(listBoardState, function (obj) {
            return _.where(obj.tasks.docs, {id: itemSelectedState.id}).length > 0;
        })
        board_selected = board_selected.length > 0 ? board_selected[0] : undefined;

        if (board_selected) {
            board_selected = JSON.parse(JSON.stringify(board_selected))
            board_selected.tasks.docs = board_selected.tasks.docs.map(item => {
                let new_item = {...item};
                if (new_item.id === itemSelectedState.id) {
                    new_item.name = itemSelectedState.name;
                }
                return new_item;
            });

            traceOrderService.updateItem(itemSelectedState.id, {name: itemSelectedState.name})
                .then(res => {
                    setListBoardState({
                        ...listBoardState,
                        [board_selected.key]: board_selected
                    })
                })
                .catch(err => {
                    console.log('updateItem', err)
                    openNotificationWithIcon('error', 'Có lỗi trong quá trình cập nhật')
                })
        }

    }

    const addNewItem = (key, board) => {
        traceOrderService.addItem({
            name: '',
            board
        })
            .then(res => {
                setListBoardState({
                    ...listBoardState,
                    [key]: {
                        ...listBoardState[key],
                        tasks: {
                            docs: [
                                res.data,
                                ...listBoardState[key].tasks.docs
                            ]
                        }
                    }
                })
            })
            .catch(err => {
                console.log('traceOrderService add item', err)
                openNotificationWithIcon('error', 'Thêm mới thất bại')
            })

    }

    const onDeleteItem = (item_id) => {
        let board_selected = _.filter(listBoardState, function (obj) {
            return _.where(obj.tasks.docs, {id: item_id}).length > 0;
        })
        board_selected = board_selected.length > 0 ? board_selected[0] : undefined;

        if (board_selected) {
            board_selected = JSON.parse(JSON.stringify(board_selected))
            let itemRemoveCount = 0;
            board_selected.tasks.docs = board_selected.tasks.docs.filter(item => {
                if (item.id === item_id)
                    itemRemoveCount++;
                return item.id !== item_id;
            });
            board_selected.tasks.totalDocs -= itemRemoveCount;

            console.log('board_selected', board_selected)
            setListBoardState({
                ...listBoardState,
                [board_selected.key]: board_selected
            });
        }
    }

    const initData = (keyword = '') => {
        setIsLoadingBoard(true);
        traceOrderService.getListBoard()
            .then((res) => {
                setListBoardState(res.data)
            })
            .finally(() => {
                setIsLoadingBoard(false);
            })
    }

    useEffect(() => {
        initData();
    }, []);

    const onSearch = (keyword) => {
        console.log('onSearch', keyword)
        traceOrderService.getListBoard(keyword)
            .then((res) => {
                setListBoardState(res.data)
            })
            .finally(() => {
                setIsLoadingBoard(false);
            })
    }

    const loadMoreData = (key, board, page = 1) => {
        console.log('loadMoreData', key)
        if (isLoadMore[key]) {
            return;
        }
        setIsLoadMore({
            ...isLoadMore,
            [key]: true
        });

        traceOrderService.getListItemByBoard(board, listBoardState[key]['tasks']['nextPage'])
            .then(res => {
                let new_data = JSON.parse(JSON.stringify(listBoardState));
                let data_append = [...new_data[key]['tasks']['docs'], ...res.data.docs];
                new_data[key]['tasks'] = res.data;
                new_data[key]['tasks']['docs'] = data_append;
                setListBoardState(new_data);
                console.log('new_data', new_data)
            })
            .finally(() =>
                setIsLoadMore({
                    ...isLoadMore,
                    [key]: false
                }))
    };

    const updateBoardFromModal = (item, board_id) => {
        let listBoardNewState = JSON.parse(JSON.stringify(listBoardState));

        let board_destination = _.filter(listBoardNewState, function (obj) {
            return obj.id === board_id;
        })
        board_destination = board_destination.length > 0 ? board_destination[0] : undefined;

        let board_source = _.filter(listBoardNewState, function (obj) {
            return _.where(obj.tasks.docs, {id: item.id}).length > 0;
        });
        board_source = board_source.length > 0 ? board_source[0] : undefined;

        let new_item_data = {...item};
        new_item_data.board = board_destination.id;
        listBoardNewState[board_destination.key].tasks.docs = [new_item_data, ...listBoardNewState[board_destination.key].tasks.docs];
        listBoardNewState[board_destination.key].tasks.totalDocs++;

        listBoardNewState[board_source.key].tasks.totalDocs--;
        listBoardNewState[board_source.key].tasks.docs = listBoardNewState[board_source.key].tasks.docs.filter(i => i.id !== new_item_data.id)

        setListBoardState(listBoardNewState);

        traceOrderService.updateItem(item.id, {board: board_destination.id})
            .then(res => {
                console.log('updateItem', res)
            })
            .catch(err => {
                console.log('err updateItem', err)
                openNotificationWithIcon('error', 'Có lỗi trong quá trình cập nhật')
            })

    }

    const handleDrag = (result) => {
        if (!result.destination || result.destination.droppableId === result.source.droppableId)
            return;

        let listBoardNewState = JSON.parse(JSON.stringify(listBoardState));
        let itemMove = listBoardNewState[result.source.droppableId]['tasks']['docs'][result.source.index];
        itemMove.board = listBoardNewState[result.destination.droppableId].id;
        let new_data = {
            ...listBoardNewState,
            [result.destination.droppableId]: {
                ...listBoardNewState[result.destination.droppableId],
                tasks: {
                    ...listBoardNewState[result.destination.droppableId]['tasks'],
                    docs: [itemMove, ...listBoardNewState[result.destination.droppableId]['tasks']['docs']]
                }
            }
        };
        new_data[result.source.droppableId]['tasks']['docs'] = removeItemByIndex(result.source.index, new_data[result.source.droppableId]['tasks']['docs'])
        setListBoardState(new_data)
        const item_id = itemMove.id;
        const board = listBoardNewState[result.destination.droppableId].id;
        traceOrderService.updateItem(item_id, {board})
            .then(res => {
                console.log('updateItem', res)
            })
            .catch(err => {
                console.log('err updateItem', err)
                openNotificationWithIcon('error', 'Có lỗi trong quá trình cập nhật')
            })
    }


    const getCurrentIndex = (current_item_id) => {
        const currentIndex = listBoardState[boardSelectedState.key].tasks.docs.findIndex(item => item.id === current_item_id);
        if (currentIndex === -1)
            return 0;
        return currentIndex;
    }
    const onNextItem = (current_item_id) => {
        const currentIndex = getCurrentIndex(current_item_id);
        let nextIndex = currentIndex + 1;
        if (nextIndex > listBoardState[boardSelectedState.key].tasks.docs.length - 1) {
            nextIndex = 0;
        }
        const item = listBoardState[boardSelectedState.key].tasks.docs[nextIndex];
        if (!item) {
            openNotificationWithIcon('error', 'Không thể di chuyển tới item tiếp theo')
        } else {
            setItemSelectedState(item);
        }
    }
    const onPrevItem = (current_item_id) => {
        const currentIndex = getCurrentIndex(current_item_id);
        let prevIndex = currentIndex - 1;
        if (prevIndex < 0) {
            prevIndex = listBoardState[boardSelectedState.key].tasks.docs.length - 1;
        }
        const item = listBoardState[boardSelectedState.key].tasks.docs[prevIndex];
        if (!item) {
            openNotificationWithIcon('error', 'Không thể di chuyển tới item trước đó')
        } else {
            setItemSelectedState(item);
        }
    }
    return (
        <div>
            <FormSearch
                placeholder={'Đơn hàng / số điện thoại'}
                onSearch={onSearch}
            />
            <ModalDetailTask visible={isModalDetailTaskVisible}
                             onOk={handleOkOnModalDetailTask}
                             onCancel={handleCancelOnModalDetailTask}
                             onUpdateItem={onUpdateItem}
                             listBoard={listBoardState}
                             onChangeBoard={updateBoardFromModal}
                             onNextItem={onNextItem}
                             onPrevItem={onPrevItem}
            />

            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Xử lý đơn hàng</Breadcrumb.Item>
            </Breadcrumb>
            {
                !isLoadingBoard
                    ?
                    <div className={'list-task-wrapper'}>
                        <DragDropContext onDragEnd={handleDrag}>
                            {
                                Object.keys(listBoardState).map(key => {
                                    return <ListTask list={listBoardState[key]} key={key} droppableId={key}
                                                     showModalDetailTask={showModalDetailTask}
                                                     addNewItem={addNewItem}
                                                     loadMoreData={loadMoreData}
                                                     onDeleteItem={onDeleteItem}
                                    />
                                })
                            }
                        </DragDropContext>
                    </div>
                    :
                    <Row justify={'center'} style={{height: '80vh'}} align={'middle'}><Col><Spin size={'large'}
                                                                                                 tip="Đang tải dữ liệu..."/></Col></Row>
            }

        </div>
    )
};

export default TraceOrderPage;