import axios from "../utils/api"
import {API_ENDPOINT} from "../config/config";

const getListBoard = (keyword = '') => {
    console.log('getListBoard', keyword)
    return axios.get(`${API_ENDPOINT}/v1/purchase/board`, {
        params: {
            keyword
        }
    })
}

const getListItemByBoard = (board, page) => {
    return axios.get(`${API_ENDPOINT}/v1/purchase/board/${board}/items`, {
        params: {page}
    })
}

const getDetailItem = (id) => {
    return axios.get(`${API_ENDPOINT}/v1/purchase/item/${id}`)
}

const deleteItem = (id) => {
    return axios.delete(`${API_ENDPOINT}/v1/purchase/item/${id}`)
}

const addItem = (data) => {
    return axios.post(`${API_ENDPOINT}/v1/purchase/item`, data)
}

const updateItem = (id, data_update) => {
    return axios.put(`${API_ENDPOINT}/v1/purchase/item/${id}`, data_update)
}

const addCommentItem = (id, data_comment) => {
    return axios.post(`${API_ENDPOINT}/v1/purchase/item/${id}/comment`, data_comment)
}

const getListBill = (purchase_item) => {
    return axios.get(`${API_ENDPOINT}/v1/purchase/item/${purchase_item}/purchase-item`)
}

const getListPurchaseBill = (options) => {
    return axios.get(`${API_ENDPOINT}/v1/purchase/list-bill`, {
        params: {
            ...options
        }
    })
}

const getSummaryPayment = (options) => {
    console.log('getSummaryPayment', options)
    return axios.get(`${API_ENDPOINT}/v1/purchase/summary-payment`, {
        params: {
            ...options
        }
    })
}

const queryPaymentHistories = (options) => axios.get(`${API_ENDPOINT}/v1/purchase/payment-histories`, {
    params: options
})

const addPaymentHistory = (id, data_payment) => {
    return axios.post(`${API_ENDPOINT}/v1/purchase/item/${id}/payment-history`, data_payment)
}

const updateDetailItem = (id, detail) => {
    return axios.post(`${API_ENDPOINT}/v1/purchase/item/${id}/detail`, {detail})
}

export default {
    getListBoard,
    getListItemByBoard,
    addItem,
    updateItem,
    getDetailItem,
    addCommentItem,
    deleteItem,
    getListBill,
    addPaymentHistory,
    updateDetailItem,
    getListPurchaseBill,
    queryPaymentHistories,
    getSummaryPayment
}