import React, {useEffect, useRef, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    List,
    Modal,
    notification,
    Popconfirm,
    Progress,
    Row,
    Select,
    Space,
    Table,
    Tabs,
    Tag
} from 'antd';
import {
    BarcodeOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ImportOutlined,
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined
} from "@ant-design/icons";
import orderService from '../../services/order.service';
import _ from 'underscore';
import ModalImportFile from "./ModalImportFile";
import './style.css'
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import FormSearch from "../../components/shared/FormSearch";
import FormFilterOrderReturn from "./FormFilterOrderReturn";


const {Option} = Select;
const {TabPane} = Tabs;

const colors_status = {
    'pending': 'red', 'returned': 'green'
}

const colors_status_order_scanned = {
    'none': 'gold',
    'new': 'cyan',
    'exists': 'volcano',
    'updated': 'blue',
}

const name_status_order_scanned = {
    'none': 'chờ xử lý',
    'new': 'thành công',
    'exists': 'đơn đã hoàn trước đó',
    'updated': 'đã cập nhật',
}

const columns = [
    {
        title: 'ID', dataIndex: 'order_id', key: 'order_id', render: (text) => <a>{text}</a>,
    },
    {
        title: 'NGƯỜI TẠO', dataIndex: 'created_by', key: 'created_by', render: (text) => <a>{text}</a>,
    },
    {
        title: 'TRẠNG THÁI',
        key: 'status',
        dataIndex: 'status',
        render: (_, {status, status_readable}) => {
            return <Tag color={colors_status[status]} key={status}>
                {status_readable.toUpperCase()}
            </Tag>
        },
    },
    {
        title: 'NGUỒN NHẬP',
        dataIndex: 'from_source_readable',
        key: 'from_source_readable',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'PHƯƠNG THỨC HOÀN',
        dataIndex: 'update_source_readable',
        key: 'update_source_readable',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Created At', dataIndex: 'created_at_format', key: 'created_at_format',
    },
    {
        title: 'Updated At', dataIndex: 'updated_at_format', key: 'updated_at_format',
    },
];

const pageSize = 10;
const ReturnOrderPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'ĐƠN HOÀN'});
    }, [])
    const inputSearchRef = useRef();
    const inputScanOrderRef = useRef();
    const [form] = Form.useForm();

    const [customerPayShip, setCustomerPayShip] = useState(true);
    const [percentProgressUpdateBulk, setPercentProgressUpdateBulk] = useState(0);
    const [statusProgressUpdateBulk, setStatusProgressUpdateBulk] = useState('normal');
    const [isStartingUpdateBulk, setIsStartingUpdateBulk] = useState(false);
    const [currentIdOrder, setCurrentIdOrder] = useState();
    const [data, setData] = useState();
    const [listOrderScanned, setListOrderScanned] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const [isModalSearchVisible, setIsModalSearchVisible] = useState(false);
    const [isModalAddNewOrderReturnVisible, setIsModalAddNewOrderReturnVisible] = useState(false);
    const showModalAddNewOrderReturn = () => {
        setIsModalAddNewOrderReturnVisible(true,);
    };
    const handleOkOnModalAddNewOrderReturn = () => {
        setIsModalAddNewOrderReturnVisible(false);
    };
    const handleCancelOnModalAddNewOrderReturn = () => {
        setIsModalAddNewOrderReturnVisible(false);
    };

    const [isModalImportFileVisible, setIsModalImportFileVisible] = useState(false);
    const showModalImportFile = () => {
        setIsModalImportFileVisible(true,);
    };
    const handleOkOnModalImportFile = () => {
        setIsModalImportFileVisible(false);
    };
    const handleCancelOnModalImportFile = () => {
        setIsModalImportFileVisible(false);
    };

    const showModalSearch = () => {
        setIsModalSearchVisible(true,);
    };
    const handleOkOnModalSearch = () => {
        setIsModalSearchVisible(false);
        onSearchOrderReturn();
    };
    const handleCancelOnModalSearch = () => {
        setIsModalSearchVisible(false);
    };

    const [isModalScanCodeVisible, setIsModalScanCodeVisible] = useState(false);

    const showModalScanCode = () => {
        setIsModalScanCodeVisible(true,);
        setIsStartingUpdateBulk(false);
        setListOrderScanned([]);
    };
    const handleOkOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };
    const handleCancelOnModalScanCode = () => {
        setIsModalScanCodeVisible(false);
    };

    const onSearchOrderReturn = (keyword) => {

        if (keyword.trim().length >= 1) {
            queryOrderReturns({
                filter: {
                    order_id: keyword
                },
                pagination: {
                    page: 1, limit: pageSize
                }
            });
        } else {
            onReloadData();
        }
        handleCancelOnModalSearch();
    };


    const queryOrderReturns = (params) => {
        setLoading(true);
        let {filter, pagination} = params;
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current, limit: pagination.pageSize
        };
        orderService.queryOrderReturns(filter, options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.docs);
                setPagination({
                    ...pagination,
                    total: res.totalDocs
                })
            })
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        queryOrderReturns({
            filter, pagination: newPagination
        })
    };

    const onReloadData = () => {
        queryOrderReturns({
            filter: {},
            pagination: {
                ...pagination,
                current: 1
            }
        })
    };

    const updateBulk = async () => {
        try {
            setStatusProgressUpdateBulk('active');
            setIsStartingUpdateBulk(true);
            setPercentProgressUpdateBulk(0);
            let result = await orderService.updateBulkOrderReturns(listOrderScanned, (percent) => {
                setPercentProgressUpdateBulk((percent));
            });
            console.log('updateBulk', result.data.result)
            setListOrderScanned(result.data.result);
            setStatusProgressUpdateBulk('success');
            onReloadData();
            notification['success']({
                message: 'Thông báo',
                description: 'Đã xử lý thành công'
            });
        } catch (e) {
            console.log('loiiiii', e);
            notification['error']({
                message: 'Thông báo',
                description: e.message
            });
            setStatusProgressUpdateBulk('exception');
        }
    }

    const onAddNewOrderReturn = async (values) => {
        console.log('Success:', values);
        try {
            await orderService.addNewOrderReturn(values);
            notification['success']({
                message: 'Thông báo',
                description: 'Thêm đơn hoàn mới thành công'
            });
            onReloadData();
            handleCancelOnModalAddNewOrderReturn();
            form.resetFields();
        } catch (e) {
            console.log(e)
            notification['error']({
                message: 'Thông báo',
                description: e.response.data.message
            });
        }
    };

    useEffect(() => {
        queryOrderReturns({
            filter, pagination
        })
    }, []);

    // handle scan order
    const onScanOrder = () => {
        let data = [
            ...listOrderScanned,
            {
                order_id: inputScanOrderRef.current.input.value,
                status: 'none'
            }
        ];

        data = _.uniq(data, order => order.order_id);
        setListOrderScanned(data);
        inputScanOrderRef.current.input.focus();
        setCurrentIdOrder('')
    };

    const onRemoveItemScan = (index) => {
        let newListOrderScanned = [...listOrderScanned];
        let itemScanSelected = newListOrderScanned[index];
        delete newListOrderScanned[index];
        newListOrderScanned = newListOrderScanned.filter(item => item)
        setListOrderScanned(newListOrderScanned);

        notification['error']({
            message: 'Thông báo',
            description: `Đã bỏ đơn hàng: ${itemScanSelected.order_id} ra khỏi danh sách`
        });
    }

    const onChangeTab = (activeKey) => {
        let filter = {};
        if (activeKey !== 'all') {
            filter.status = activeKey;
        }
        queryOrderReturns({
            filter, pagination
        })
    }

    const onFilter = (data_filter) => {
        console.log('data_filter', data_filter)
        let new_pagination = {
            current: 1,
            pageSize: pageSize,
        };
        queryOrderReturns({
            filter: data_filter, pagination: new_pagination
        })
    }
    return <div>
        <FormSearch
            placeholder={'Mã đơn hàng'}
            onSearch={onSearchOrderReturn}
        />
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Đơn hoàn</Breadcrumb.Item>
        </Breadcrumb>
        <div
            className="site-layout-background"
            style={{
                padding: 24, minHeight: 360,
            }}
        >
            <Row style={{}}>
                <Col>
                    <Space>
                        <Button type={"danger"} icon={<PlusOutlined/>} onClick={showModalAddNewOrderReturn}/>
                        <Button style={{background: '#faad14', color: 'white', border: "none"}}
                                icon={<ImportOutlined/>}
                                onClick={showModalImportFile}/>

                        <Button type={"primary"} icon={<ReloadOutlined/>} onClick={onReloadData}/>
                        <Button style={{background: 'rgb(82, 196, 26)', color: 'white', border: "none"}}
                                icon={<BarcodeOutlined/>}
                                onClick={showModalScanCode}/>
                        {/*<Button style={{background: 'rgb(64, 169, 255)', color: 'white', border: "none"}}*/}
                        {/*        icon={<SearchOutlined/>}*/}
                        {/*        onClick={showModalSearch}/>*/}
                    </Space>
                </Col>
            </Row>
            <br/>

            <ModalImportFile visible={isModalImportFileVisible}
                             onReloadData={onReloadData}
                             onOk={handleOkOnModalImportFile}
                             onCancel={handleCancelOnModalImportFile}/>

            <Modal
                title="Nhập đơn hàng hoàn"
                visible={isModalAddNewOrderReturnVisible}
                onOk={handleOkOnModalAddNewOrderReturn}
                onCancel={handleCancelOnModalAddNewOrderReturn}
                footer={<></>}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onAddNewOrderReturn}
                >
                    <Form.Item label="Mã vận đơn" name={'order_id'} required
                               rules={[{required: true, message: 'Vui lòng nhập mã vận đơn'}]}
                    >
                        <Input placeholder="nhập mã vận đơn" prefix={<BarcodeOutlined/>}/>
                    </Form.Item>
                    <Form.Item name={'reason_return'} label="Lý do hoàn" required
                               rules={[{required: true, message: 'Vui lòng chọn lý do hoàn'}]}
                    >
                        <Select placeholder="chọn lý do hoàn" prefix={<BarcodeOutlined/>}>
                            <Option value="Không ưng sản phẩm">Không ưng sản phẩm</Option>
                            <Option value="Bom hàng">Bom hàng</Option>
                            <Option value="Hàng lỗi">Hàng lỗi</Option>
                            <Option value="Lên sai đơn">Lên sai đơn</Option>
                            <Option value="Đổi hàng">Đổi hàng</Option>
                            <Option value="Lý do khác">Lý do khác</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={'customer_pay_ship'}>
                        <Checkbox name={'customer_pay_ship'} checked={customerPayShip} onChange={() => {
                            setCustomerPayShip(!customerPayShip);
                        }}>
                            Khách trả ship
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button>HUỶ</Button>
                            <Button type="primary" htmlType={'submit'}>THÊM MỚI</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Tìm kiếm đơn hàng" visible={isModalSearchVisible} onOk={handleOkOnModalSearch}
                   onCancel={handleCancelOnModalSearch}
            >
                <Input
                    size="large"
                    placeholder="Nhập mã vận đơn"
                    prefix={<SearchOutlined/>}
                    ref={inputSearchRef}
                    onPressEnter={() => onSearchOrderReturn(inputSearchRef.current.input.value)}
                />
            </Modal>

            <Modal title="Quét hàng hoàn"
                   visible={isModalScanCodeVisible}
                   onOk={handleOkOnModalScanCode}
                   onCancel={handleCancelOnModalScanCode}
                   okText={'XỬ LÝ ĐƠN'}
                   footer={[
                       <Button onClick={handleCancelOnModalScanCode}>Đóng</Button>,
                       <Popconfirm
                           title="Danh sách đơn hàng đã quét sẽ bị xoá"
                           onConfirm={() => {
                               setListOrderScanned([])
                           }}
                           onCancel={() => {
                           }}
                           okText="Xoá"
                           cancelText="Huỷ"
                       >
                           <Button type={'danger'}>Xoá data</Button>
                       </Popconfirm>,
                       <Button type={'primary'} onClick={updateBulk}>Xử Lý</Button>,
                   ]}
            >
                <Space direction={'vertical'} style={{width: '100%'}}>
                    <Input
                        size="large"
                        placeholder="quét mã vận đơn"
                        prefix={<BarcodeOutlined/>}
                        ref={inputScanOrderRef}
                        value={currentIdOrder}
                        onPressEnter={onScanOrder}
                        onChange={(e) => setCurrentIdOrder(e.target.value)}
                    />
                    <Row align={'center'}>
                        {
                            isStartingUpdateBulk ? <Progress
                                status={statusProgressUpdateBulk}
                                percent={percentProgressUpdateBulk}
                            /> : ''
                        }
                    </Row>
                    <List
                        style={{
                            maxHeight: 400,
                            overflowY: 'scroll',
                            marginTop: 10
                        }}
                        header={`DANH SÁCH ĐƠN (${listOrderScanned.length})`}
                        bordered={true}
                        dataSource={listOrderScanned}
                        renderItem={(item, index) => (
                            <List.Item key={item}>
                                <CheckCircleOutlined style={{color: '#52c41a'}}/> {item.order_id} <Tag
                                onClick={() => {
                                    onRemoveItemScan(index)
                                }}
                                color={'red'}
                                style={{float: 'right', cursor: 'pointer'}}><CloseCircleOutlined/></Tag> <Tag
                                color={colors_status_order_scanned[item.status]}
                                style={{float: 'right'}}>{name_status_order_scanned[item.status]}</Tag>
                            </List.Item>
                        )}
                    />
                </Space>
            </Modal>

            <div className="card-container">
                <FormFilterOrderReturn onSubmit={onFilter}/>
                <br/>
                <Table
                    rowKey={(record) => record.order_id}
                    pagination={pagination}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                />
            </div>

        </div>
    </div>
}

export default ReturnOrderPage;