import React, {useEffect, useState} from 'react';
import {Avatar, Button, Comment, Drawer, Form, Image, Input, List, Select, Space, Tooltip, Upload} from 'antd';
import {
    CloudUploadOutlined,
    DownCircleOutlined,
    DownOutlined,
    FieldTimeOutlined,
    UpOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {itemSelectedAtom} from "../../recoil/atom/trace-order.atom";
import {userProfileAtom} from "../../recoil/atom/user.atom";
import traceOrderService from "../../services/trace-order.service";
import moment from "moment";
import styled from "styled-components"
import {API_ENDPOINT} from "../../config/config";

const {Option} = Select;

const {TextArea} = Input;

const TableDetail = styled.table`

`

const TableRow = styled.tr`
`

const TableColumn = styled.td`
  padding: 10px;
  color: #7f7878;

  &:nth-child(2n+1) {
    width: 180px;
  }
`
const ControlButton = styled.button`
  font-size: 17px;
  color: #dbdbdb;
  border: none;
  background: rgba(0, 0, 0, -0.5);
  cursor: pointer;
`

const NextItemButton = styled(ControlButton)`
  right: -75px;
`
const PrevItemButton = styled(ControlButton)`
  left: -75px;
`

const EditorComment = ({onChange, onSubmit, submitting, value, setFile, accessToken}) => {
    const [fileList, setFileList] = useState([]);
    const props = {
        name: 'file',
        action: `${API_ENDPOINT}/v1/storage/upload`,
        headers: {
            authorization: accessToken
        },
        multiple: true,
        onChange(info) {
            console.log('info', info)
            if (info.file.status === 'done') {
                setFile(info.file.response.url);
                console.log(info)
                // message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
            let newFileList = [info.file]; // 1. Limit the number of uploaded files

            newFileList = fileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }

                return file;
            });
            setFileList(newFileList);
        }
    };
    return (
        <>
            <Form.Item>
                <Input onChange={onChange} value={value} placeholder={'Nội dung'} onPressEnter={onSubmit}
                />
            </Form.Item>
            <Form.Item>
                <Space align={'baseline'} style={{justifyContent: 'space-between', width: '100%'}}>
                    <Upload {...props} >
                        <Button icon={<CloudUploadOutlined/>}>File đính kèm</Button>
                    </Upload>
                    <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                        Bình luận
                    </Button>
                </Space>

            </Form.Item>
        </>
    )
};

const CommentList = (props) => {

    const [listComment, setListComment] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    useEffect(() => {
        console.log('loading comment')
        traceOrderService.getDetailItem(props.itemID)
            .then(res => {
                let data = [];
                data = res.data.comments.map(comment => {
                    return {
                        author: comment.user.full_name,
                        avatar: comment.user.avatar,
                        content: (
                            <p>
                                {comment.content}
                                {
                                    comment.hasOwnProperty('file') ? <p>
                                        <Image src={comment.file} width={200}/>
                                    </p> : ''
                                }
                            </p>
                        ),
                        datetime: (
                            <Tooltip title={moment(comment.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comment.created_at).fromNow()}</span>
                            </Tooltip>
                        ),
                    }
                });
                data = data.reverse()
                setListComment(data);
            })
    }, [props])


    return <List
        className="comment-list"
        header={`${listComment.length} replies`}
        itemLayout="horizontal"
        dataSource={listComment}
        renderItem={(item) => (
            <li>
                <Comment
                    actions={item.actions}
                    author={item.author}
                    avatar={item.avatar}
                    content={item.content}
                    datetime={item.datetime}
                />
            </li>
        )}
    />
}

function getTitleItem(itemSelectedState, setItemSelectedState) {
    return <TextArea autoSize={true}
                     value={itemSelectedState.name}
                     bordered={false}
                     style={{fontWeight: '600', fontSize: '35px'}}
                     onChange={(e) => {
                         let new_state = {...itemSelectedState};
                         new_state['name'] = e.target.value;
                         setItemSelectedState(new_state);
                     }}
                     placeholder={'Nhập tiêu đề'}
    />;
}

const ModalDetailTask = (props) => {

    const [itemSelectedState, setItemSelectedState] = useRecoilState(itemSelectedAtom);
    const userProfileState = useRecoilValue(userProfileAtom);
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const [file, setFile] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const listBoardSelect = [];
    for (let board in props.listBoard) {
        let {id, name} = props.listBoard[board];
        listBoardSelect.push({label: name, value: id});
    }

    const handleChangeBoard = (board) => {
        props.onChangeBoard(itemSelectedState, board)
        setItemSelectedState({
            ...itemSelectedState,
            board
        })
    };

    const handleSubmit = () => {
        if (!value) return;
        setSubmitting(true);
        let data_comment = {
            user: userProfileState.id,
            content: value,
        };
        if (file !== '') {
            data_comment.file = file;
        }

        traceOrderService.addCommentItem(itemSelectedState.id, data_comment)
            .then(res => {
                console.log('addCommentItem', res)
            })
            .catch(err => {
                console.log('addCommentItem err', err)
            })
            .finally(() => {
                setSubmitting(false);
                setValue('');
            })

    };


    return <Drawer
        title={<>
            <NextItemButton onClick={() => props.onPrevItem(itemSelectedState.id)}><UpOutlined/></NextItemButton>
            <PrevItemButton onClick={() => props.onNextItem(itemSelectedState.id)}><DownOutlined/></PrevItemButton>
            {getTitleItem(itemSelectedState, setItemSelectedState)}
            <div>
                <TableDetail>
                    <TableRow>
                        <TableColumn><Space size={5}><FieldTimeOutlined/> Ngày
                            tạo</Space>
                        </TableColumn>
                        <TableColumn>{moment(itemSelectedState.created_at).format('HH:mm:ss DD/MM/YYYY')}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn><Space size={5}><UserOutlined/> Người tạo</Space>
                        </TableColumn>
                        <TableColumn>{itemSelectedState.created_by?.full_name}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn><Space size={5}><DownCircleOutlined/> Trạng thái</Space>
                        </TableColumn>
                        <TableColumn>
                            <Select
                                value={itemSelectedState.board}
                                style={{
                                    width: 250,
                                }}
                                bordered={false}
                                onChange={handleChangeBoard}
                                options={listBoardSelect}
                            />
                        </TableColumn>
                    </TableRow>
                </TableDetail>
            </div>
        </>}
        closeIcon={<></>}
        visible={props.visible}
        onOk={props.onOk}
        onClose={props.onCancel}
        afterClose={props.onUpdateItem}
        width={700}
        destroyOnClose={true}

        // bodyStyle={{overflowY: 'scroll'}}
    >
        <div style={{position: 'relative'}}>
            <Space direction={'vertical'} style={{width: "100%"}}>
                <Comment
                    avatar={<Avatar src={userProfileState.avatar}/>}
                    content={
                        <EditorComment
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitting={submitting}
                            setFile={setFile}
                            value={value}
                            accessToken={userProfileState.access_token}
                        />
                    }
                />

                {
                    props.visible ? <CommentList visible={props.visible} itemID={itemSelectedState.id}/>
                        : <></>
                }
            </Space>

        </div>
    </Drawer>


}

export default ModalDetailTask;