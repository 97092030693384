import {Breadcrumb, Table, Tag, Typography} from "antd";
import {useRecoilState} from "recoil";
import {pageInfoAtom} from "../../recoil/atom/page.atom";
import {useEffect, useState} from "react";
import FormSearch from "../../components/shared/FormSearch";
import purchaseService from "../../services/purchase.service";
import {formatCurrency} from "../../utils/common";
import moment from "moment";
import TablePurchaseBill from "./TablePurchaseBill";
import FormFilterPurchaseBill from "./FormFilterPurchaseBill";

const {Text} = Typography;

const payment_readable = {
    'unpaid': {
        color: 'red',
        text: 'Chưa thanh toán'
    },
    'paying': {
        color: 'orange',
        text: 'Đang thanh toán'
    },
    'paid': {
        color: 'green',
        text: 'Đã thanh toán'
    },

}
const pageSize = 20;
const columns = [
    {
        title: 'ID', dataIndex: '_id', key: '_id', render: (text) => text.substring(text.length - 4),
    },
    {
        title: 'NHÀ CUNG CẤP',
        dataIndex: 'purchase_item',
        key: 'purchase_item',
        render: (purchase_item) => purchase_item?.supplier,
    },
    {
        title: 'LÔ HÀNG',
        dataIndex: 'purchase_item',
        key: 'purchase_item_name',
        render: (purchase_item) => purchase_item?.name,
    },
    {
        title: 'SẢN PHẨM',
        dataIndex: 'purchase_item',
        key: 'product_name',
        render: (purchase_item) => purchase_item?.product,
    },
    {
        title: 'NGÀY NHẬP', dataIndex: 'created_at', key: 'created_at',
        render: created_at => moment(created_at).format('HH:mm:ss DD/MM/YYYY')
    },
    {
        title: 'TỔNG NHẬP',
        dataIndex: 'detail',
        key: 'total',
        render: (detail) => {
            if (detail) {
                return detail.reduce((s, f) => {
                    return s + f.qty;
                }, 0)
            } else {
                return 0;
            }
        }
    },
    {
        title: 'GIÁ TRỊ',
        dataIndex: 'detail',
        key: 'cost',
        render: (detail) => {
            let cost = 0
            if (detail) {
                cost = detail.reduce((s, f) => {
                    return s + f.qty * f.price;
                }, 0)
            }

            return formatCurrency(cost);
        }
    },
    {
        title: 'THANH TOÁN',
        dataIndex: 'purchase_item',
        key: 'payment_status',
        render: (purchase_item) => {
            return <Tag
                color={payment_readable[purchase_item.payment_status]['color']}>{payment_readable[purchase_item.payment_status]['text']}</Tag>
        }
    }
];

const PurchaseHistoryPage = () => {
    const [pageInfoState, setPageInfoState] = useRecoilState(pageInfoAtom);
    const [data, setData] = useState();
    const [summary, setSummary] = useState({});
    const [pagination, setPagination] = useState({
        current: 1, pageSize: pageSize,
    });
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const onSearch = (keyword) => {
        console.log('onSearch', keyword)
    }


    const queryListPurchaseBill = (params) => {
        setLoading(true);
        let {filter, pagination, cast} = params;
        cast = cast ? cast : {};
        setPagination(pagination);
        setFilter(filter);
        let options = {
            page: pagination.current,
            limit: pagination.pageSize,
            filter: JSON.stringify(filter),
            cast: JSON.stringify(cast)
        };
        purchaseService.getListPurchaseBill(options)
            .then(res => res.data)
            .then((res) => {
                setLoading(false);
                setData(res.data.docs);
                setSummary({
                    total_qty: res.summary[0]?.total_qty,
                    total_cost: res.summary[0]?.total_cost,
                })
                setPagination({
                    ...pagination,
                    total: res.data.metadata.total_docs
                })
            })
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        queryListPurchaseBill({
            filter, pagination: newPagination
        })
    };

    const onFilter = (data_filter) => {
        console.log('data_filter', data_filter)
        let new_pagination = {
            current: 1,
            pageSize: pageSize,
        };
        queryListPurchaseBill({
            filter: data_filter, pagination: new_pagination
        })
    }

    useEffect(() => {
        setPageInfoState({...pageInfoState, title: 'NHẬP HÀNG'});
        queryListPurchaseBill({
            filter, pagination
        })
    }, [])
    return (
        <div>
            <FormSearch
                placeholder={'Ngày nhập/lô hàng'}
                onSearch={onSearch}
            />
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>History</Breadcrumb.Item>
            </Breadcrumb>
            <FormFilterPurchaseBill onSubmit={onFilter}/>
            <br/>
            <Table
                rowKey={(record) => record._id}
                expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                                background: 'rgb(240 242 245)',
                                padding: 10
                            }}
                        >
                            <TablePurchaseBill data={record.detail}/>
                        </p>
                    ),
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                pagination={pagination}
                loading={loading}
                columns={columns}
                dataSource={data}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={6}><Text
                                    type="success">TỔNG</Text></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Text type="success">{summary.total_qty}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    <Text type="success">{formatCurrency(summary.total_cost)}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
                onChange={handleTableChange}
            />
        </div>
    )
};

export default PurchaseHistoryPage;